import {
  Payout,
  PayoutResponse,
  ProgressReport,
  Project,
  ProjectBalance,
  ProjectFile,
  ProjectTransactionResponse,
} from '../types/ProjectTypes';
import { CurrentUserResponse } from '../types/UserTypes';
import config from './config';
import { ApiError } from './error';
import { getHost } from './helpers/host';
import { getToken, removeToken } from './token';
import { cdnClient, client, ContentTypes, Query, ResponseData } from './galaxyClient';
import { extractPaginatedUrl } from './helpers/pagination';
import { paths as accountingPaths } from './galaxyClient/types/Accounting';
import { paths as authPaths } from './galaxyClient/types/Authentication';

const API_URL: string = config.API_URL;

export const handleErrorCategory = async (errorType: string, response: Response) => {
  let message = '';
  let json: Record<string, unknown> = {};
  try {
    message = await response.text();
    json = JSON.parse(message);
  } catch {
    message = message || response.statusText;
  }

  throw new ApiError(
    errorType,
    response.status,
    json.errorId as string,
    json.errorCode as string,
    (json.error || json.errorMessage) as string,
    message,
  );
};

const handleMiscErrors = async (response: Response) => {
  if (response.status >= 400 && response.status < 500) {
    await handleErrorCategory('Client error', response);
  } else if (response.status >= 500 && response.status < 600) {
    await handleErrorCategory('Server error', response);
  }
};

export type queryParams =
  | string
  | number
  | {
      [x: string]: string | string[] | number | number[] | boolean | undefined | null;
    };

type requestOptions = {
  query?: queryParams;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: {
    [x: string]: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  fullUrl?: boolean;
};

export const authenticatedFetch = async <T>(
  path: string,
  options: requestOptions = {},
): Promise<T> => {
  const url = options.fullUrl ? path : `${API_URL}${path}${toQueryString(options.query)}`;

  const response = await fetch(url, {
    method: options.method || 'GET',
    headers: new Headers({
      Authorization: `Bearer ${getToken()}`,
      'x-application-name': 'enterprise',
      ...options.headers,
    }),
    body: options.body,
  });

  if (response.status === 401) {
    removeToken();
    await handleErrorCategory('Unauthorized error', response);
  }

  await handleMiscErrors(response);

  const contentType = response.headers.get('content-type');
  if (contentType?.match('application/json')) {
    const json = await response.json();
    return json as T;
  }

  return response as unknown as T;
};

export async function getJWTToken(
  accessToken: string,
): Promise<authPaths['/v2/firebase/']['get']['responses']['200']['content']['application/json']> {
  const response = await fetch(`${API_URL}authentication/v2/firebase?access_token=${accessToken}`);

  if (response.status === 401) {
    const body = await response.json();
    if (body?.code == 103) {
      throw new Error('missing_email');
    }

    if (body?.code == 106) {
      const error = new Error(body.errorMessage);
      error.name = body.name;
      throw error;
    }

    throw new Error();
  }

  await handleMiscErrors(response);

  return await response.json();
}

export async function contentFetch<T>(url: string, options?: requestOptions) {
  return await authenticatedFetch<T>(`content/v2${url}`, options);
}

export const getCurrentUser = async () =>
  await authenticatedFetch<CurrentUserResponse>('users/v2/me');

export const acceptTermsOfUse = async (slug: string) =>
  await client().users.POST('/v2/me/accept-terms', {
    body: {
      slug,
    },
  });

export const sendEmailVerification = async (firebaseToken?: string, url?: string) => {
  const response = await fetch(`${API_URL}authentication/v2/firebase/verify-email`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ accessToken: firebaseToken, url }),
  });
  if (response.status !== 200) throw new Error('Failed email verification');
};

export type Currency = {
  code: string;
  name: string;
  cents: number;
  providers: string[];
};
export const getCurrencies = (providerName?: string) => {
  const query = toQueryString({ providerName });
  return getFromGalaxyCDN<{ result: Currency[] }>(`accounting/v2/currencies${query}`);
};

export type PaymentProvider =
  accountingPaths['/v2/providers/']['get']['responses']['200']['content']['application/json']['result'][number];

export type StripePaymentProvider = PaymentProvider & {
  name: 'stripe';
  configuration: {
    publishableKey: string;
    stripeAccountId: string | null;
  };
};

export const getPaymentProviders = ({
  currencyCode,
  grantmakerName,
  categoryId,
  projectId,
}: {
  currencyCode: string;
  grantmakerName?: string;
  categoryId?: string;
  projectId?: number;
}) =>
  getFromGalaxyCDN<
    accountingPaths['/v2/providers/']['get']['responses']['200']['content']['application/json']
  >(`accounting/v2/providers`, {
    currencyCode,
    grantmakerName,
    categoryId,
    projectId,
  });

export type AmountSuggestion = {
  amount: number;
  default: boolean;
  currency: { code: string; name: string; symbol: string };
};

export const getAmountSuggestions = ({
  currencyCode,
  configuration = 'default',
}: {
  currencyCode: string;
  configuration?: string;
}) =>
  getFromGalaxyCDN<{ result: AmountSuggestion[] }>(
    `accounting/v2/amount-suggestions/${currencyCode}/${configuration}`,
  );

export enum PaymentMethodType {
  Card = 'CARD',
  DirectDebit = 'DIRECT_DEBIT',
  Wallet = 'WALLET',
}

export type PaymentMethod = {
  id: string;
  default?: boolean;
  created: string;
  card: {
    year: number;
    brand: CardBrand;
    last4: string;
    month: number;
    wallet?: {
      dynamic_last4: string;
      type: WalletType;
    };
  };
  type: PaymentMethodType;
};
export type PaymentMethodsResponse = {
  publicKey: string;
  result: PaymentMethod[];
};

export const getStripePaymentMethods = async () =>
  await authenticatedFetch<PaymentMethodsResponse>(
    `accounting/v2/providers/stripe/payment-methods`,
  );

export const deleteStripePaymentMethod = async (paymentMethodId: string) =>
  await authenticatedFetch(`accounting/v2/providers/stripe/payment-methods/${paymentMethodId}`, {
    method: 'DELETE',
  });

export const setDefaultStripePaymentMethod = async (paymentMethodId: string) =>
  await authenticatedFetch(`accounting/v2/providers/stripe/payment-methods/${paymentMethodId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      default: true,
    }),
  });

export const addStripePaymentMethod = async (paymentMethodId: string) =>
  authenticatedFetch<{ pendingSetupIntent: { status: string; client_secret: string } | null }>(
    `accounting/v2/providers/stripe/payment-methods`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        id: paymentMethodId,
      }),
    },
  );

export const getKlarnaPaymentMethods = async (): Promise<{
  result: (PaymentMethod & { directDebit?: { maskedNumber?: string } })[];
}> => await authenticatedFetch(`accounting/v2/providers/klarna/payment-methods`);

export enum PaymentProviderName {
  Klarna = 'klarna',
  Partner = 'partner',
  Stripe = 'stripe',

  // Legacy, only used for existing invoices
  Gift = 'gift',
  Swish = 'swish',
}

export enum WalletName {
  apple_pay = 'Apple Pay',
  google_pay = 'Google Pay',
}
export enum WalletType {
  ApplePay = 'apple_pay',
  GooglePay = 'google_pay',
}

export enum CardBrand {
  Visa = 'visa',
  MasterCard = 'mastercard',
  AmericanExpress = 'amex',
}
export enum CardName {
  amex = 'American Express',
  mastercard = 'MasterCard',
  visa = 'Visa',
}

export type PaymentMethodName = WalletName | CardName;

export type Invoice = {
  // FIXME: This type is incomplete. It's missing the `items` property that's found in the response.
  id?: number;
  userId?: number;
  provider?: string;
  createdAt?: string;
  updatedAt?: string;
  amountTotal?: number;
  amountPaid?: number;
  amountPaidInCents?: number;
  subscriptionId?: number;
  userPortfolioId?: string | null;
  userPortfolioVersion?: number | null;
  editedBy?: number | null;
  editedAt?: string | null;
  currencyCode?: string;
  status?: string;
  paymentProviderName?: PaymentProviderName;
  grantmaker?: { id: string; name: string };
  affiliates?: {
    resourceType?: string;
    id?: string;
    title?: string;
  }[];
  providerData?: {
    donationProgramName?: string;
    donationProgramId?: string;
    donationProgramType?: string;
    donationProgramRequiresConfirmation?: boolean;
    partnerId?: string;
    partnerName?: string;
    giftId?: string;
    orderId?: string;
    pendingSetupIntent?: {
      status?: string;
    } | null;
    paymentMethod?: PaymentMethod | null;
    gift?: {
      id?: string;
      period?: number;
      giverName?: string;
    };
  };
};

export const getMyPendingInvoices = () =>
  authenticatedFetch<{ result: Invoice[] }>('accounting/v2/invoices', {
    query: { me: true, pending: true },
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

export type PortfolioItem = {
  id: number;
  impacterId?: number;
  externalImpacterId?: string;
  initiativeId?: string;
  categoryId?: string;
};

export type UserPortfolio = {
  id: string;
  userId: number;
  currentVersion: number;
  items: PortfolioItem[];
};

export const getUserPortfolio = async () =>
  await authenticatedFetch<{
    result: UserPortfolio[];
  }>('accounting/v2/user-portfolios?me=true');

export const addItemToPortfolio = async (item: Omit<PortfolioItem, 'id'>) =>
  await authenticatedFetch(`accounting/v2/user-portfolios/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(item),
  });

export const deleteItemFromPortfolio = async (portfolioId: string, itemId: string | number) =>
  await authenticatedFetch(`accounting/v2/user-portfolios/${portfolioId}/items/${itemId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export type AffiliateSupporter = {
  firstName: string;
  lastName: string;
  message: string;
  externalProfileImage: string;
  currencyCode: string;
  amount: number;
};

/**
 * @param projectId - projectId as number
 *   Fetch project balances per project
 */
export const getProjectBalances = (
  projectId: string | number,
): Promise<{ result: ProjectBalance[] }> =>
  authenticatedFetch(`accounting/v2/balances/projects/${projectId}?includeExtendedBalances=true`);

/**
 * @param projectId - projectId as number
 *   Fetch transactions per project.
 */
export const getProjectTransactions = (
  projectId: number,
  options: {
    query?: queryParams;
  },
) =>
  // FIXME: This one can return GroupedProjectTransactionResponse as well which has different properties.
  // Suggestion could be to add a second declaration where we specify which return type is used
  // for different arguments (https://www.typescriptlang.org/docs/handbook/functions.html#overloads)
  authenticatedFetch<ProjectTransactionResponse>(
    `accounting/v2/projects/${projectId}/transactions`,
    options,
  );

/**
 * @param projectId - projectId as number
 *   Fetch payouts per project.
 */
export const getPayouts = (
  projectId: number,
  options: {
    query?: queryParams;
  },
) => authenticatedFetch<PayoutResponse>(`accounting/v2/projects/${projectId}/payouts`, options);

/**
 * @param projectId - projectId as number
 * @param payoutItemId - payoutItemId as string
 *   Fetch payout item
 */
export const getPayoutItem = (projectId: number, payoutItemId: string) =>
  authenticatedFetch<Payout>(`accounting/v2/projects/${projectId}/payout-items/${payoutItemId}`);

export async function getProjectsByFilter({
  impacterId,
  initiativeId,
  organisationId,
}: {
  impacterId?: string;
  initiativeId?: string;
  organisationId?: string;
}): Promise<Project[]> {
  const query = toQueryString({ impacterId, organisationId, initiativeId });
  const { result } = await authenticatedFetch<{ result: Project[] }>(
    `accounting/v2/projects${query}`,
  );
  return result;
}

export async function getProject(projectId: number): Promise<Project | undefined> {
  return await authenticatedFetch<Project>(`accounting/v2/projects/${projectId}`);
}

export async function getProjectBudgetTemplate(
  projectId: string,
): Promise<ProjectFile | undefined> {
  const {
    result: [template],
  } = await authenticatedFetch<{ result: ProjectFile[] }>(
    `accounting/v2/projects/${projectId}/files?uploadType=budget-template`,
  );
  return template;
}

export async function getProjectFiles(
  projectId: string,
  { uploadTypes }: { uploadTypes?: string[] } = {},
): Promise<ProjectFile[]> {
  const query = toQueryString({ uploadType: uploadTypes });
  const { result: files } = await authenticatedFetch<{ result: ProjectFile[] }>(
    `accounting/v2/projects/${projectId}/files${query}`,
  );
  return files;
}

export async function getProjectProgressReports(projectId: string): Promise<ProgressReport[]> {
  const { result } = await authenticatedFetch<{ result: ProgressReport[] }>(
    `accounting/v2/projects/${projectId}/progress-reports`,
  );
  return result;
}

export async function createProjectProgressReportFile(
  projectId: string,
  progressReportId: string,
  {
    fileName,
    mimeType,
  }: {
    fileName: string;
    mimeType: string;
  },
): Promise<{ id: string; key: string; uploadUrl: string }> {
  return await authenticatedFetch<{ id: string; key: string; uploadUrl: string }>(
    `accounting/v2/projects/${projectId}/progress-reports/${progressReportId}/files`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: fileName,
        mimeType: mimeType,
        originalName: fileName,
      }),
    },
  );
}

export async function updateProjectProgressReportFile(
  projectId: string,
  progressReportId: string,
  fileId: string,
  {
    name,
    status,
  }: {
    name?: string;
    status?: 'UPLOADED';
  },
): Promise<{ id: string }> {
  return await authenticatedFetch<{ id: string }>(
    `accounting/v2/projects/${projectId}/progress-reports/${progressReportId}/files/${fileId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        status,
      }),
    },
  );
}

export async function deleteProjectProgressReportFile(
  projectId: string,
  progressReportId: string,
  fileId: string,
): Promise<void> {
  await authenticatedFetch(
    `accounting/v2/projects/${projectId}/progress-reports/${progressReportId}/files/${fileId}`,
    { method: 'DELETE' },
  );
}

export async function downloadProjectProgressReportFile(
  projectId: string,
  progressReportId: string,
  fileId: string,
): Promise<void> {
  const { downloadUrl } = await authenticatedFetch<{ downloadUrl: string }>(
    `accounting/v2/projects/${projectId}/progress-reports/${progressReportId}/files/${fileId}/download`,
  );
  window.open(downloadUrl, '_blank');
}

export async function downloadProjectFile(projectId: string, fileId: string): Promise<void> {
  const { downloadUrl } = await authenticatedFetch<{ downloadUrl: string }>(
    `accounting/v2/projects/${projectId}/files/${fileId}/download`,
  );
  window.open(downloadUrl, '_blank');
}

export const getLocale = async () =>
  await getFromGalaxyCDN<{
    country: {
      countryCode: string;
      name?: string;
      defaultCurrencyCode: string;
      blockedFor: string[];
    };
  }>('users/v2/locale');

type PaymentRequestResponse = {
  id: string;
  status: string;
  currencyCode: string;
  amount: number;
  createdAt: string;
  firstName: string;
  lastName: string;
  email: string;
  paymentProviderName: string;
  description: string;
  userId?: number;
};

export const getPaymentRequestByExternalId = async (externalId: string) =>
  await authenticatedFetch<PaymentRequestResponse>(`accounting/v2/payment-requests/${externalId}`);

type PaymentRequestParamsStripe = {
  amount: number;
  currencyCode: string;
  projectIds?: number[];
  impacterIds?: number[];
  paymentMethod: string;
  email: string;
  firstName?: string;
  lastName?: string;
  contentfulLandingPageId: string;
  emailOptIn: boolean;
  initiator?: string;
  affiliateName?: string;
  categoryIds?: string[];
  language?: string;
  skipAmountValidation?: boolean;
  verificationToken?: string;
  donationProgramId?: string;
  partnerId?: string;
  grantmakerId?: string;
};

type PaymentRequestResponseStripe = {
  id: string;
  requiresAction: boolean;
  paymentIntentClientSecret: string;
  status: string;
};

export const createPaymentRequestForStripe = async ({
  amount,
  currencyCode,
  impacterIds,
  projectIds,
  contentfulLandingPageId,
  paymentMethod,
  email,
  firstName,
  lastName,
  initiator,
  affiliateName,
  emailOptIn,
  categoryIds,
  language,
  skipAmountValidation,
  verificationToken,
  donationProgramId,
  partnerId,
  grantmakerId,
}: PaymentRequestParamsStripe) =>
  await authenticatedFetch<PaymentRequestResponseStripe>(
    'accounting/v2/providers/stripe/payment-requests',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-request-check': verificationToken ?? '',
        ...(skipAmountValidation ? { 'Skip-Amount-Validation': 'true' } : {}),
      },
      body: JSON.stringify({
        amount,
        currencyCode,
        projectIds,
        impacterIds,
        paymentMethod,
        email,
        contentfulLandingPageId,
        firstName,
        lastName,
        emailOptIn,
        initiator,
        affiliateName,
        categoryIds,
        language,
        donationProgramId,
        partnerId,
        grantmakerId,
      }),
    },
  );

type PaymentRequestParamsKlarna = {
  amount: number;
  currencyCode: string;
  language: string;
  country: string;
  initiator: string;
  contentfulLandingPageId: string;
  affiliateName?: string;
  impacterIds?: number[];
  categoryIds?: string[];
  recurring?: boolean;
  skipAmountValidation?: boolean;
  donationProgramId?: string;
  partnerId?: string;
};

export const createPaymentRequestForKlarna = async ({
  amount,
  currencyCode,
  language,
  country,
  contentfulLandingPageId,
  initiator,
  affiliateName,
  impacterIds,
  categoryIds,
  recurring,
  skipAmountValidation,
  donationProgramId,
  partnerId,
}: PaymentRequestParamsKlarna) =>
  await authenticatedFetch<{
    id: string;
    clientToken: string;
    paymentMethodCategories: { identifier: string; name: string }[];
  }>('accounting/v2/providers/klarna/payment-requests', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(skipAmountValidation ? { 'Skip-Amount-Validation': 'true' } : {}),
    },
    body: JSON.stringify({
      amount,
      currencyCode,
      language,
      country,
      initiator,
      contentfulLandingPageId,
      affiliateName,
      impacterIds,
      categoryIds,
      recurring,
      donationProgramId,
      partnerId,
    }),
  });

export const updatePaymentRequestForKlarna = async ({
  paymentRequestId,
  recurring,
  amount,
  contentfulLandingPageId,
}: {
  paymentRequestId: string;
  contentfulLandingPageId: string;
  recurring: boolean;
  amount: number;
}) => {
  await authenticatedFetch(`accounting/v2/providers/klarna/payment-requests/${paymentRequestId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      recurring,
      amount,
      contentfulLandingPageId,
    }),
  });
};

type KlarnaOrderResponse = {
  orderId: string;
  userId: number;
};

export const createKlarnaOrder = async ({
  authorization_token,
  paymentRequestId,
  contentfulLandingPageId,
  amount,
  currencyCode,
}: {
  authorization_token: string;
  paymentRequestId: string;
  contentfulLandingPageId: string;
  amount: number;
  currencyCode: string;
}) =>
  await authenticatedFetch<KlarnaOrderResponse>('accounting/v2/providers/klarna/order', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      authorization_token,
      paymentRequestId,
      amount,
      contentfulLandingPageId,
      currencyCode,
    }),
  });

type KlarnaSubscriptionParams = {
  authorizationToken: string;
  amount: number;
  currencyCode: string;
  country: string;
  locale: string;
  contentfulLandingPageId: string;
  affiliateName?: string;
  categoryIds: string[];
  donationProgramId?: string;
  partnerId?: string;
};

type CreateKlarnaSubscriptionResponse = {
  subscription: { id: number; currentVersion: number; externalId: string };
  user: { id: number };
};
export const createKlarnaSubscription = async ({
  authorizationToken,
  amount,
  currencyCode,
  country,
  affiliateName,
  categoryIds,
  contentfulLandingPageId,
  locale,
  donationProgramId,
  partnerId,
}: KlarnaSubscriptionParams): Promise<CreateKlarnaSubscriptionResponse> =>
  await authenticatedFetch('accounting/v2/providers/klarna/subscriptions', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      authorizationToken,
      amount,
      currencyCode,
      purchaseCountry: country,
      affiliateName,
      categoryIds,
      locale,
      contentfulLandingPageId,
      donationProgramId,
      partnerId,
    }),
  });

type PaymentMethodParamsKlarna = {
  authorizationToken: string;
  currencyCode: string;
  purchaseCountry: string;
  locale: string;
};

export const updatePaymentMethodForKlarna = async ({
  authorizationToken,
  currencyCode,
  locale,
  purchaseCountry,
}: PaymentMethodParamsKlarna) => {
  return authenticatedFetch<{ ok: string; statusText: string }>(
    'accounting/v2/providers/klarna/payment-methods',
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        authorizationToken,
        currencyCode,
        purchaseCountry,
        locale,
      }),
    },
  );
};

/**
 * Only supports a specific set of parameters, update in /src/pages/api
 */
export const getFromEnterpriseBackend = async <T>(
  path: string,
  query?: queryParams,
): Promise<T> => {
  return await get(`${getHost()}/api/${path}`, { query, name: 'Enterprise' });
};

// Their names should be put inline considering the fact that getFromEnterpriseBackend also exist
export const getFromGalaxy = async <T>(
  path: string,
  query?: queryParams,
  options?: requestOptions,
): Promise<T> => {
  return await get<T>(`${config.API_URL}${path}`, { query, name: 'Galaxy' }, options);
};

export const getFromGalaxyCDN = async <T>(
  path: string,
  query?: queryParams,
  options?: requestOptions,
): Promise<T> => {
  return await get<T>(`${config.CDN_API_URL}${path}`, { query, name: 'Galaxy CDN' }, options);
};

export const getFromGalaxyCDNRaw = async (path: string, query?: queryParams): Promise<Response> => {
  const response = await fetch(`${config.CDN_API_URL}${path}${toQueryString(query)}`, {
    headers: { 'x-application-name': 'enterprise' },
  });
  if (!response.ok) await handleErrorCategory('API Galaxy CDN', response);
  return response;
};

export const get = async <T>(
  url: string,
  { query, name }: { query?: queryParams; name?: string } = {},
  options: requestOptions = {},
): Promise<T> => {
  const response = await fetch(url + toQueryString(query), {
    ...options,
    headers: { 'x-application-name': 'enterprise', ...options.headers },
  });
  if (!response.ok) await handleErrorCategory(name ? `Api ${name}` : 'Api', response);
  return await response.json();
};

function toQueryString(query?: queryParams): string {
  if (!query) return '';

  if (typeof query == 'object') {
    // { a: 1, b: [2, 3, undefined], c: undefined } to a=1&b=2&b=3
    query = Object.entries(query)
      .flatMap(([key, values]) =>
        []
          // @ts-expect-error ts-migrate(2769) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
          .concat(values)
          .filter((value) => value !== undefined)
          .map((value) => [encodeURIComponent(key), encodeURIComponent(value)]),
      )
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
  }

  return `?${query}`;
}

export function claimUserInvite(payload: string, firebaseId: string) {
  return authenticatedFetch(`/authentication/v2/users/invite`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      payload,
      firebaseId,
    }),
  });
}

export type Initiative = ResponseData<ContentTypes, '/v2/initiatives/{initiativeId}', 'get'>;
type InitiativesFilter = Query<ContentTypes, '/v2/initiatives/', 'get'>;

export const getInitiatives = async (filters?: InitiativesFilter, paginationUrl?: string) => {
  if (paginationUrl) {
    const { data } = await client().fetch<ContentTypes, '/v2/initiatives/', 'get'>(
      extractPaginatedUrl(paginationUrl, API_URL),
    );
    return data;
  }
  const { data } = await client().content.GET('/v2/initiatives/', {
    params: {
      query: filters,
    },
  });
  return data;
};

export const getInitiative = async (
  id: string,
  { skipContentfulData }: { skipContentfulData?: boolean } = {},
) => {
  const { data } = await cdnClient().content.GET('/v2/initiatives/{initiativeId}', {
    params: {
      path: {
        initiativeId: id,
      },
      query: {
        skipContentfulData,
      },
    },
  });

  return data;
};

type PillarsFilters = Query<ContentTypes, '/v2/pillars/', 'get'>;
export const getPillars = async (query: PillarsFilters) => {
  const { data } = await cdnClient().content.GET('/v2/pillars/', {
    params: {
      query,
    },
  });

  return data;
};

type ApproachesFilters = Query<ContentTypes, '/v2/approaches/', 'get'>;
export const getApproaches = async (query: ApproachesFilters) => {
  const { data } = await cdnClient().content.GET('/v2/approaches/', {
    params: {
      query,
    },
  });

  return data;
};
