import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';
import {
  EXCLUDE_FROM_GOOGLE_AND_FACEBOOK_TRACKING_PATHS,
  EXCLUDE_FROM_GOOGLE_AND_FACEBOOK_TRACKING_QUERY_SLUGS,
} from '../constants/excludeFromTracking';
import { loadScript, TRACKING_ID } from './gtm';
import { sendGTMEvent } from './gtm/sendGTMEvent';

const GTMTracking = () => {
  const router = useRouter();

  const isDisabled =
    EXCLUDE_FROM_GOOGLE_AND_FACEBOOK_TRACKING_PATHS.has(router.asPath) ||
    typeof router.query.ppcPartner === 'string' ||
    (typeof router.query.slug === 'string' &&
      EXCLUDE_FROM_GOOGLE_AND_FACEBOOK_TRACKING_QUERY_SLUGS.has(router.query.slug));

  if (isDisabled) {
    return null;
  }

  return <GTMTrackingScript />;
};

const GTMTrackingScript = () => {
  const router = useRouter();
  useEffect(() => {
    loadScript();
  }, []);

  useEffect(() => {
    const loadComplete = () => {
      sendGTMEvent('pageView');
    };
    router.events.on('routeChangeComplete', loadComplete);
    return () => {
      router.events.off('routeChangeComplete', loadComplete);
    };
  });

  return <Script src={`https://www.googletagmanager.com/gtm.js?id=${TRACKING_ID}`} async={true} />;
};

export default GTMTracking;
