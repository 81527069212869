import { useEffect, useState } from 'react';
import { breakpointQueries } from '../styleguide';

export function useMediaQuery(query: string) {
  const [match, setMatch] = useState(false);
  const [determined, setDetermined] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    function mediaQueryListener(e: MediaQueryListEvent) {
      setMatch(e.matches);
    }
    setMatch(mediaQuery.matches);
    setDetermined(true);
    mediaQuery.addListener(mediaQueryListener);
    return () => mediaQuery.removeListener(mediaQueryListener);
  }, [query]);

  return [match, determined];
}

export function useByMediaQuery<T>({
  mobile,
  tablet,
  desktop,
  xl,
}: {
  mobile?: T;
  tablet?: T;
  desktop?: T;
  xl?: T;
}) {
  const [isTablet] = useMediaQuery(breakpointQueries.tablet);
  const [isDesktop] = useMediaQuery(breakpointQueries.desktop);
  const [isXl] = useMediaQuery(breakpointQueries.xl);

  if (isXl && !!xl) return xl;
  if (isDesktop && !!desktop) return desktop;
  if (isTablet && !!isTablet) return tablet;
  return mobile;
}
