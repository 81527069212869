const UptimeTracking = () => {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s){w._uptime_rum={};w._uptime_rum.uuid='BPOP-AB2F5760105098A3';w._uptime_rum.url='https://rum.uptime.com/rum/record-data';s=document.createElement('script');s.async=1;s.src='https://rum.uptime.com/static/rum/compiled/rum.js';d.getElementsByTagName('head')[0].appendChild(s);})(window,document);`,
      }}
    />
  );
};

export default UptimeTracking;
