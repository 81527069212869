import PrimaryButton from '../../component-library/buttons/PrimaryButton';
import styled from '@emotion/styled';
import ButtonPromptWrapper from '../../component-library/ButtonPromptWrapper';
import { NavBarButtonType } from '../util/navigation';

const ButtonWrapper = styled(ButtonPromptWrapper)`
  display: block;
`;

type Props = {
  button?: NavBarButtonType;
};

const ActionNavBarButton = ({ button }: Props) => {
  if (!button) return null;
  return (
    <ButtonWrapper prompt={button?.prompt}>
      <PrimaryButton
        color={button?.colorContext}
        label={button?.label}
        variant={button?.variant}
        tag="a"
        href={button?.href}
        size={button?.size}
        trailingIconName={button?.trailingIconName}
        leadingIconName={button?.leadingIconName}
      />
    </ButtonWrapper>
  );
};

export default ActionNavBarButton;
