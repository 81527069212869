import styled from '@emotion/styled';
import { ReactNode, useRef } from 'react';
import { useOutsideClick } from '../../../../utils/hooks/useOutsideClick';
import { colors, spacing } from '../../../../utils/styleguide';
import { HandleNavItemSelected } from '../../../contentful-elements/navigationbar/common';
import { NavLinkItemType } from '../navTypes';
import SubmenuLink from './LinksGroup/SubmenuLink';
import MobileNavBarWrapper from './MobileNavBarWrapper';

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[1]}px;
  border-left: 1px solid ${colors.grey02};
`;

type Props = {
  isOpened: boolean;
  linkGroups?: NavLinkItemType[];
  handleNavItemClick: HandleNavItemSelected;
  button?: ReactNode | null;
  bottomLinks?: NavLinkItemType[];
  clickOutside: (event: Event) => void;
};

const MobileNavBar = ({
  isOpened,
  linkGroups,
  handleNavItemClick,
  button,
  clickOutside,
}: Props) => {
  const mobileNavBarRef = useRef(null);
  useOutsideClick({ innerRef: mobileNavBarRef, onOutsideClick: clickOutside });

  return (
    <MobileNavBarWrapper isOpened={isOpened} ref={mobileNavBarRef}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '85%',
        }}
      >
        <LinkContainer>
          {linkGroups?.map(({ title, titleLink, id }) => (
            <SubmenuLink
              key={id}
              title={title}
              href={titleLink}
              onClick={() => handleNavItemClick({ id })}
            />
          ))}
        </LinkContainer>

        {button}
      </div>
    </MobileNavBarWrapper>
  );
};

export default MobileNavBar;
