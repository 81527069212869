import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeMicrocopySkeleton } from './TypeMicrocopy';

export interface TypeMicrocopySetFields {
  name: EntryFieldTypes.Symbol;
  description?: EntryFieldTypes.Symbol;
  type?: EntryFieldTypes.Symbol<
    | 'Email Template'
    | 'Green Game Jam Ecosystem'
    | 'Map Content'
    | 'Standalone'
    | 'Thank You Smart Move Template'
  >;
  settings?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeMicrocopySkeleton>>;
  resources: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeMicrocopySkeleton>>;
}

export type TypeMicrocopySetSkeleton = EntrySkeletonType<TypeMicrocopySetFields, 'microcopySet'>;
export type TypeMicrocopySet<
  Modifiers extends ChainModifiers = 'WITHOUT_UNRESOLVABLE_LINKS',
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypeMicrocopySetSkeleton, Modifiers, Locales>;

export function isTypeMicrocopySet<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeMicrocopySet<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'microcopySet';
}
