import styled from '@emotion/styled';
import { colors } from '../../utils/styleguide';
import Icon from './Icon';

const StyledIcon = styled(Icon)<{ color: string }>`
  path {
    transition: fill 0.3s ease;
    ${({ color }) => `fill: ${color};`}
  }
`;

type Props = {
  color?: string;
  height?: number | string;
  width?: number | string;
};

const LogoBrandIcon = ({ color = colors.white, height = 35, width = 'auto' }: Props) => {
  return (
    <StyledIcon
      name={'milkywire-symbol'}
      color={color}
      size={height}
      height={height}
      width={width}
    />
  );
};

export default LogoBrandIcon;
