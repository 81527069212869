// eslint-disable-next-line no-restricted-imports
import { init } from '@sentry/nextjs';

type Options = Parameters<typeof init>[0];

export const getOptions = (options: Options): Options => {
  const overrides: Options = {};
  // When we're developing locally
  if (process.env.NODE_ENV !== 'production' && process.env.NEXT_PUBLIC_ACTIVATE_SENTRY !== 'true') {
    // Instead, dump the errors to the console
    overrides.beforeSend = (event, hint) => {
      const error = hint?.originalException || hint?.syntheticException;
      if (error) {
        // eslint-disable-next-line no-console
        console.trace(error);
      }
      return event;
    };

    overrides.defaultIntegrations = false;
  }
  return {
    ...options,
    ...overrides,
  };
};
