import { useMediaQuery } from '../../../utils/hooks/useMediaQuery';
import { breakpointQueries } from '../../../utils/styleguide';
import { Props } from './common';
import ContentfulDesktopBar from './ContentfulDesktopBar';
import ContentfulMobileBar from './ContentfulMobileBar';

const ContentfulNavBar = (props: Props) => {
  const [isDesktop] = useMediaQuery(breakpointQueries.desktop);
  const NavBar = isDesktop ? ContentfulDesktopBar : ContentfulMobileBar;
  return <NavBar {...props} />;
};

export default ContentfulNavBar;
