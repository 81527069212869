import { TypeLink } from '../../../../@types/generated';
import { isTypeLandingPage } from '../../../../@types/generated/TypeLandingPage';
import { isTypePageSection } from '../../../../@types/generated/TypePageSection';
import { getLandingPageUrl } from './pageUrl';
import { getSectionId } from './sectionToCompatibleProps';

const getInternalHref = ({ reference }: { reference: TypeLink['fields']['internalRef'] }) => {
  if (!reference) {
    return;
  }
  if (isTypeLandingPage(reference)) {
    return getLandingPageUrl({
      slug: reference.fields.slug,
      pageTags: reference.fields.tags,
    });
  } else if (isTypePageSection(reference)) {
    const id = getSectionId({ section: reference });
    return `#${id}`;
  }
};

const getLinkHref = (link?: TypeLink) => {
  if (!link) return undefined;
  return link.fields?.externalUrl || getInternalHref({ reference: link.fields?.internalRef });
};

export default getLinkHref;
