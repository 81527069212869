import { css } from '@emotion/react';
import { AllHTMLAttributes, CSSProperties, ElementType, forwardRef, useContext } from 'react';
import { breakpoints } from '../../../utils/styleguide';
import { captureException } from '../../../utils/tracking/sentry/sentry';
import { TypographyColorContext } from './TypographyColorContext';

const styles = {
  display: css`
    font-size: 61px;
    line-height: 73px;
    font-weight: 300;
    text-wrap: pretty;

    ${breakpoints.tablet} {
      font-size: 76px;
      line-height: 91px;
    }

    ${breakpoints.desktop} {
      font-size: 95px;
      line-height: 114px;
    }
  `,
  h1: css`
    font-size: 39px;
    line-height: 47px;
    font-weight: 400;
    text-wrap: pretty;

    ${breakpoints.tablet} {
      font-size: 49px;
      line-height: 67px;
    }

    ${breakpoints.desktop} {
      font-size: 61px;
      line-height: 73px;
    }
  `,
  h2: css`
    font-size: 31px;
    line-height: 43px;
    font-weight: 400;
    text-wrap: pretty;

    ${breakpoints.tablet} {
      font-size: 39px;
      line-height: 55px;
    }
    ${breakpoints.desktop} {
      font-size: 49px;
      line-height: 58px;
    }
  `,
  h3: css`
    font-size: 28px;
    line-height: 39px;
    font-weight: 400;
    text-wrap: pretty;

    ${breakpoints.tablet} {
      font-size: 35px;
      line-height: 49px;
    }
    ${breakpoints.desktop} {
      font-size: 39px;
      line-height: 46px;
    }
  `,
  h4: css`
    font-size: 25px;
    line-height: 35px;
    font-weight: 400;
    text-wrap: pretty;

    ${breakpoints.tablet} {
      font-size: 31px;
      line-height: 43px;
    }
    ${breakpoints.desktop} {
      font-size: 31px;
      line-height: 43px;
    }
  `,
  h5: css`
    font-size: 22px;
    line-height: 31px;
    font-weight: 400;
    text-wrap: pretty;

    ${breakpoints.tablet} {
      font-size: 25px;
      line-height: 35px;
    }
  `,
  h6: css`
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    text-wrap: pretty;
  `,

  body: css`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  `,
  bodySmall: css`
    font-size: 13px;
    line-height: 18px;
  `,
  overline: css`
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  `,
  overlineSmall: css`
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
  `,
  caption: css`
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 1.3px;
    text-transform: uppercase;
  `,
  subtitle: css`
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
  `,
  subtitleSmall: css`
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
  `,
  detail: css`
    font-size: 13px;
    line-height: 18px;
  `,
  detailSmall: css`
    font-size: 10px;
    line-height: 14px;
  `,
  code: css`
    font-size: 13px;
    line-height: 18px;
    font-family: Monaco, monospace;
  `,
  buttonSmall: css`
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1.3px;

    text-transform: uppercase;
    cursor: pointer;
  `,
  buttonLarge: css`
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 1.6px;

    text-transform: uppercase;
    cursor: pointer;
  `,
};

export const variants = {
  h1: {
    tag: 'h1',
    css: styles.h1,
  },
  h2: {
    tag: 'h2',
    css: styles.h2,
  },
  h3: {
    tag: 'h3',
    css: styles.h3,
  },
  h3Bold: {
    tag: 'h3',
    css: css`
      ${styles.h3}
      font-weight: 500;
    `,
  },

  h4: {
    tag: 'h4',
    css: styles.h4,
  },
  h4Bold: {
    tag: 'h4',
    css: css`
      ${styles.h4}
      font-weight: 500;
    `,
  },

  h5: {
    tag: 'h5',
    css: styles.h5,
  },
  h5Bold: {
    tag: 'h5',
    css: css`
      ${styles.h5}
      font-weight: 500;
    `,
  },

  h6: {
    tag: 'h6',
    css: styles.h6,
  },
  h6Bold: {
    tag: 'h6',
    css: css`
      ${styles.h6}
      font-weight: 500;
    `,
  },

  body: {
    tag: 'p',
    css: styles.body,
  },
  bodyBold: {
    tag: 'p',
    css: css`
      ${styles.body}
      font-weight: 500;
    `,
  },

  bodySmall: {
    tag: 'p',
    css: styles.bodySmall,
  },
  bodySmallBold: {
    tag: 'p',
    css: css`
      ${styles.bodySmall}
      font-weight: 500;
    `,
  },

  overline: {
    tag: 'span',
    css: styles.overline,
  },
  overlineBold: {
    tag: 'span',
    css: css`
      ${styles.overline}
      font-weight: 500;
    `,
  },
  overlineSmall: {
    tag: 'span',
    css: styles.overlineSmall,
  },

  caption: {
    tag: 'span',
    css: styles.caption,
  },

  subtitle: {
    tag: 'span',
    css: styles.subtitle,
  },
  subtitleSmall: {
    tag: 'span',
    css: styles.subtitleSmall,
  },

  detail: {
    tag: 'p',
    css: styles.detail,
  },
  detailBold: {
    tag: 'p',
    css: css`
      ${styles.detail}
      font-weight: 500;
    `,
  },
  detailSmall: {
    tag: 'p',
    css: styles.detailSmall,
  },

  code: {
    tag: 'p',
    css: styles.code,
  },

  buttonLarge: {
    tag: 'button',
    css: styles.buttonLarge,
  },
  buttonSmall: {
    tag: 'button',
    css: styles.buttonSmall,
  },
};

const limitedNumberOfLines = css`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
`;

export type TypographyVariants = keyof typeof variants;

export type TypographyProps = AllHTMLAttributes<HTMLElement> & {
  variant?: TypographyVariants;
  tag?: string;
  value?: string;
  svgName?: string;
  // NB: Do not use in new code, this only exists for legacy code
  underlineColor?: string;
  useBuiltInMargins?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  maxNumberOfLines?: number;
  hoverColor?: string;
};

const resetMargin = css`
  margin: 0;
`;

const Typography = forwardRef<HTMLElement, TypographyProps>(
  (
    {
      variant = 'body',
      tag,
      children,
      color,
      hoverColor,
      className,
      href,
      onClick,
      type,
      // @ts-expect-error Property 'css' does not exist on type ....
      css: cssOverrides,
      value,
      useBuiltInMargins,
      maxNumberOfLines,
      disabled,
      style,
      ...rest
    },
    ref,
  ) => {
    delete rest.svgName;
    delete rest.underlineColor;

    const { color: colorContext } = useContext(TypographyColorContext);
    const colorTypography = color || colorContext;

    let variantData = variants[variant];

    if (!variantData) {
      variantData = variants.body;
      captureException(
        new Error(`Typography variant ${variant} does not exist. Using "body" instead.`),
      );
    }

    let Tag = tag as ElementType;
    if (tag === undefined) {
      Tag = variantData.tag as ElementType;
    }

    return (
      <Tag
        ref={ref}
        css={css`
          color: ${colorTypography};
          text-decoration: none;
          font-smooth: always;
          -webkit-font-smoothing: antialiased;
          text-wrap: pretty;
          transition: color ease-in-out 150ms;

          :hover {
            color: ${hoverColor};
          }

          b {
            font-weight: 500;
          }
          ${!useBuiltInMargins && resetMargin};
          ${variantData ? variantData.css : ''};
          ${cssOverrides};
          ${maxNumberOfLines !== undefined &&
          css`
            ${limitedNumberOfLines}
            -webkit-line-clamp: ${maxNumberOfLines};
          `}
        `} // Issue with forwardRef + emotion css array, see https://github.com/emotion-js/emotion/issues/2134
        className={className}
        href={href}
        onClick={onClick}
        type={type}
        value={value}
        disabled={disabled}
        style={style}
        {...rest}
      >
        {children}
      </Tag>
    );
  },
);

Typography.displayName = 'Typography';

export default Typography;
