import styled from '@emotion/styled';
import { useRef } from 'react';
import { useOutsideClick } from '../../../../../utils/hooks/useOutsideClick';
import { colors, spacing } from '../../../../../utils/styleguide';
import { NavItemType, NavLinkItemType } from '../../navTypes';
import SubmenuLink from './SubmenuLink';
import Container from '../../../layout/Container';
import Typography from '../../../text/Typography';

const SubmenuWrapper = styled.div`
  position: absolute;
  width: 100%;
  background: ${colors.white};
  padding: ${spacing[4]}px ${spacing[5]}px;
  border-bottom: 1px solid ${colors.grey02};
`;

const LinkContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${spacing[2]}px;
  border-left: 1px solid ${colors.grey03};
`;

const StyledTypography = styled(Typography)`
  padding: 0 ${spacing[4]}px;
  margin-bottom: ${spacing[2]}px;
  display: flex;
`;

const Wrapper = styled.div`
  display: flex;
  gap: ${spacing[4]}px;
  margin-bottom: ${spacing[4]}px;
`;

type SubmenuProps = {
  links?: (NavItemType | NavLinkItemType)[] | undefined;
  clickOutside: (event: Event) => void;
  onLinkClicked: () => void;
};

const Submenu = ({ links, clickOutside, onLinkClicked }: SubmenuProps) => {
  const submenuRef = useRef(null);
  useOutsideClick({ innerRef: submenuRef, onOutsideClick: clickOutside });
  if (!links) return null;
  const linkGroups: Omit<NavLinkItemType, 'id'>[] = links.filter(
    (link): link is NavLinkItemType => 'links' in link,
  );
  const navItems = links.filter((link): link is NavItemType => 'href' in link && !!link.href);
  if (linkGroups.length > 0 && !!navItems.length)
    linkGroups.splice(0, 0, { title: '', links: navItems });

  return (
    <SubmenuWrapper ref={submenuRef}>
      <Container disableVerticalPadding>
        {linkGroups?.length > 0 ? (
          <Wrapper>
            {linkGroups.map(({ title, links: linkGroupLinks }, index) => {
              return (
                <LinkContainer key={title + index}>
                  {title && (
                    <StyledTypography variant="overlineBold" color={colors.blackSecondary}>
                      {title}
                    </StyledTypography>
                  )}
                  {linkGroupLinks
                    ?.filter((link): link is NavItemType => 'href' in link)
                    .map(({ title, href, description }: NavItemType, index) => {
                      return (
                        <SubmenuLink
                          key={index}
                          href={href}
                          title={title}
                          description={description}
                          onClick={onLinkClicked}
                        />
                      );
                    })}
                </LinkContainer>
              );
            })}
          </Wrapper>
        ) : (
          <LinkContainer>
            {navItems.map(({ title, href, description }: NavItemType, index) => {
              return (
                <SubmenuLink
                  key={index}
                  href={href}
                  title={title}
                  description={description}
                  onClick={onLinkClicked}
                />
              );
            })}
          </LinkContainer>
        )}
      </Container>
    </SubmenuWrapper>
  );
};

export default Submenu;
