import styled from '@emotion/styled';
import { useCallback } from 'react';
import LinkGroups from '../../component-library/navigation/NavBar/LinksGroup';
import Submenu from '../../component-library/navigation/NavBar/LinksGroup/Submenu';
import NavBarContainer from '../../component-library/navigation/NavBar/NavBarContainer';
import { Home, Props, useNavBar } from './common';
import PoweredByMWNavBar from '../../component-library/navigation/NavBar/PoweredByMWNavBar';
import HeaderWrapper from '../../component-library/navigation/NavBar/HeaderWrapper';

const LinkNavigation = styled.nav`
  pointer-events: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const ContentfulDesktopBar = ({ navigationBar, initiallyTransparent, partnerLogoSrc }: Props) => {
  const {
    submenu,
    clearSubmenu,
    linkGroups,
    navButton,
    handleNavItemSelected,
    logoGroup,
    poweredByMilkywire,
    textColor,
  } = useNavBar({ navigationBar });
  const isMenuOpened = !!submenu;

  const hideSubmenu = useCallback(
    (event: Event) => {
      event.stopPropagation();
      if (
        !event.target ||
        !(event.target instanceof Element) ||
        !event.target?.closest('#navbar')
      ) {
        clearSubmenu();
      }
    },
    [clearSubmenu],
  );

  if (poweredByMilkywire)
    return <PoweredByMWNavBar color={textColor || undefined} partnerLogoSrc={partnerLogoSrc} />;

  return (
    <HeaderWrapper
      initiallyTransparent={initiallyTransparent}
      overrideTransparent={isMenuOpened}
      hideNavBar={clearSubmenu}
      preventHideNavBar={isMenuOpened}
      textColor={textColor || undefined}
    >
      {({ textColor }) => (
        <>
          <NavBarContainer>
            <Home
              textColor={textColor}
              handleNavItemClick={handleNavItemSelected}
              logoGroup={logoGroup}
            />
            {!!linkGroups.length && (
              <LinkNavigation>
                <LinkGroups
                  linkGroups={linkGroups}
                  handleNavItemClick={handleNavItemSelected}
                  isMenuOpened={isMenuOpened}
                  textColor={textColor}
                />
              </LinkNavigation>
            )}
            <Spacer />
            {navButton}
          </NavBarContainer>
          <Submenu clickOutside={hideSubmenu} links={submenu?.links} onLinkClicked={clearSubmenu} />
        </>
      )}
    </HeaderWrapper>
  );
};

export default ContentfulDesktopBar;
