import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { DeviceSpec } from '../../utils/helpers/image';
import { breakpoints } from '../../utils/styleguide';
import AspectRatioBox from './layout/AspectRatioBox';

type ImageContainerProps = {
  deviceWidths?: DeviceSpec;
};

const ImageContainer = styled.div<ImageContainerProps>`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 100%;
  ${({ deviceWidths }) => `
  ${deviceWidths?.mobile && `max-width: ${deviceWidths?.mobile}px;`}
  ${breakpoints.tablet} {
    ${deviceWidths?.tablet && `max-width: ${deviceWidths?.tablet}px;`}
  }
  ${breakpoints.desktop} {
    ${deviceWidths?.desktop && `max-width: ${deviceWidths?.desktop}px;`}
  }
`}
  margin: 0 auto;
`;

type ImageFitWrapperProps = {
  deviceWidths?: DeviceSpec;
  aspectRatio?: number;
  responsiveAspectRatios?: DeviceSpec;
  children: ReactNode;
  className?: string;
};

export const ImageFitWrapper = ({
  className,
  children,
  deviceWidths,
  aspectRatio,
  responsiveAspectRatios,
}: ImageFitWrapperProps) => {
  return (
    <ImageContainer deviceWidths={deviceWidths} className={className}>
      <AspectRatioBox aspectRatio={aspectRatio} responsiveAspectRatios={responsiveAspectRatios}>
        {children}
      </AspectRatioBox>
    </ImageContainer>
  );
};

export const getImageFitStyles = () => ({
  css: css`
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 100%;
  `,
  wrapperCss: css`
    flex: 1;
    height: 100%;
  `,
});
