import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeLinkSkeleton } from './TypeLink';

export interface TypeContextualButtonFields {
  identifier: EntryFieldTypes.Symbol;
  label: EntryFieldTypes.Symbol;
  prompt?: EntryFieldTypes.Symbol;
  link?: EntryFieldTypes.EntryLink<TypeLinkSkeleton>;
}

export type TypeContextualButtonSkeleton = EntrySkeletonType<
  TypeContextualButtonFields,
  'contextualButton'
>;
export type TypeContextualButton<
  Modifiers extends ChainModifiers = 'WITHOUT_UNRESOLVABLE_LINKS',
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypeContextualButtonSkeleton, Modifiers, Locales>;

export function isTypeContextualButton<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeContextualButton<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'contextualButton';
}
