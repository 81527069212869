export default function isSvgUrl(url: string): boolean {
  if (!url.endsWith('.svg') || !url.endsWith('.svgz')) return false;

  let urlObject;
  try {
    urlObject = new URL(url);
  } catch (_) {
    return false;
  }

  return urlObject.protocol === 'http:' || urlObject.protocol === 'https:';
}
