export const getApiUrl = (env?: string) => {
  switch (env) {
    case 'staging':
    case 'test-staging':
    case 'dev-staging':
      return 'https://api.staging.milkywire.com/';
    case 'production':
    case 'dev-production':
      return 'https://api.milkywire.com/';
    default:
      return 'http://localhost:5000/';
  }
};

export const getHostUrl = (env?: string) => {
  switch (env) {
    case 'staging':
      return 'https://www.staging.milkywire.com';
    case 'production':
      return 'https://www.milkywire.com';
    default:
      return 'http://localhost:3000';
  }
};

export const getCdnApiUrl = (env?: string) => {
  switch (env) {
    case 'staging':
    case 'dev-staging':
      return 'https://api-cdn.staging.milkywire.com/';
    case 'test-staging':
      return getApiUrl(env);
    case 'production':
    case 'dev-production':
      return 'https://api-cdn.milkywire.com/';
    default:
      return 'http://localhost:5000/';
  }
};
