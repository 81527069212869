import { createContext } from 'react';
import { colors } from '../../../utils/styleguide';

type Color = {
  color: string;
};

/**
 * Use this context when you want to set a color for many Typography components from a parent container,
 * instead of having to specify the color manually for each Typography
 */
export const TypographyColorContext = createContext<Color>({
  color: colors.black, // default color when no context was provided
});
