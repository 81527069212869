import styled from '@emotion/styled';
import Link from 'next/link';
import { colors, spacing } from '../../../utils/styleguide';
import Container from '../../component-library/layout/Container';
import LogoBrandIcon from '../../component-library/LogoBrandIcon';
import NavigationLinks from '../../component-library/navigation/Footer/NavigationLinks';
import PolicyLinksAndInfo from '../../component-library/navigation/Footer/PolicyLinksAndInfo';
import { FooterBarClientProps, NavigationAsset } from '../util/navigation';
import Typography from '../../component-library/text/Typography';
import { NavItemType } from '../../component-library/navigation/navTypes';

const GoHomeLink = styled.a`
  display: block;
  margin-bottom: ${spacing[8]}px;
  width: fit-content;
`;

const Divider = styled.hr`
  border-top: 1px solid ${colors.grey03};
  margin: ${spacing[6]}px 0;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[1]}px;
  margin-top: ${spacing[5]}px;

  img {
    height: ${spacing[4]}px;
    width: ${spacing[4]}px;
    object-fit: contain;
  }
`;

export type ContentfulFooterProps = {
  footerBar: FooterBarClientProps;
  className?: string;
};

const ContentfulFooter = ({ footerBar, className }: ContentfulFooterProps) => {
  const { linkGroups, storeGroups, socialMedia, bottomLinks, disclaimer, copyright } = footerBar;

  return (
    <Container backgroundColor={colors.grey50} style={{ borderTop: `1px solid ${colors.grey02}` }}>
      <nav aria-label="Footer" className={className}>
        <Link href="/" passHref legacyBehavior>
          <GoHomeLink aria-label="go-home">
            <LogoBrandIcon height={40} color={colors.blackPrimary} />
          </GoHomeLink>
        </Link>

        <NavigationLinks textLinks={linkGroups} imageLinks={storeGroups} />

        <Divider />

        <PolicyLinksAndInfo
          policyLinks={bottomLinks?.filter(
            (link): link is NavItemType => 'assetProps' in link || 'href' in link,
          )}
        />

        {socialMedia.length > 0 && (
          <IconsContainer>
            {socialMedia.map(({ title, assetProps, href }: NavItemType, index) => {
              return (
                <Link href={href} passHref={!!href} key={index} aria-label={title}>
                  {assetProps && <NavigationAsset {...assetProps} />}
                </Link>
              );
            })}
          </IconsContainer>
        )}

        {disclaimer && (
          <Typography
            variant="bodySmall"
            color={colors.blackSecondary}
            css={{ marginTop: `${spacing[5]}px` }}
          >
            {disclaimer}
          </Typography>
        )}

        {copyright && (
          <Typography
            variant="bodySmall"
            color={colors.blackSecondary}
            css={{ marginTop: `${spacing[1]}px` }}
          >
            {copyright}
          </Typography>
        )}
      </nav>
    </Container>
  );
};

export default ContentfulFooter;
