import { useEffect, useState } from 'react';

export function useResizeHeight() {
  const [height, setHeight] = useState(0); // Can't use window here as it's not defined server-side
  useEffect(() => {
    function onChange(event: UIEvent) {
      const target = event.target as Window;
      if (target) {
        setHeight(target.innerHeight);
      }
    }
    window.addEventListener('resize', onChange);
    return () => window.removeEventListener('resize', onChange);
  }, []);
  return height;
}
