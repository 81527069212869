// All routes that should be excluded from any kind of tracking
export const EXCLUDE_FROM_TRACKING_PATHS = new Set([
  '/whistleblowing',
  '/pricelessplanet/donate',
  '/pricelessplanet/donate?mode=app',
]);

export const EXCLUDE_FROM_GOOGLE_AND_FACEBOOK_TRACKING_PATHS = new Set(['/pricelessplanet']);
export const EXCLUDE_FROM_GOOGLE_AND_FACEBOOK_TRACKING_QUERY_SLUGS = new Set([
  'thank-you-smart-move-ppc-override',
]);
