import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '../../utils/styleguide';
import Icon from './Icon';

const beat = keyframes`
  from, 20%, 40%, to {
    width: 90%;
  }
  10%, 30% {
    width: 100%;
  }
`;

const fadeinDelayed = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Wrapper = styled.div<Pick<Props, 'overlay'>>`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  ${({ overlay }) =>
    overlay &&
    css`
      animation: ${fadeinDelayed} 1s ease-out;
      ::before {
        content: ' ';
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: ${colors.black};
        opacity: 0.5;
      }
    `}
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 70px;
  height: 70vh;
  padding: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = { overlay?: boolean; className?: string };

const Loader = ({ overlay, className }: Props) => (
  <Wrapper data-testid="loader" className={className} overlay={overlay}>
    <LoaderContainer>
      <Icon
        name="milkywire-symbol"
        css={css`
          width: 100%;
          height: 100%;
          animation: ${beat} 1s linear infinite;
          fill: ${overlay ? colors.yellow : 'black'};
        `}
      />
    </LoaderContainer>
  </Wrapper>
);

export default Loader;
