import publicRuntimeConfig from './config';

/**
 * Issue with nextjs not using assetPrefix server-side
 * https://github.com/vercel/next.js/issues/39722
 */
export const getAssetUrlWithAssetPrefix = (assetUrl: string) =>
  assetUrl.startsWith('/') && publicRuntimeConfig.ASSET_PREFIX
    ? `${publicRuntimeConfig.ASSET_PREFIX}${assetUrl}`
    : assetUrl;
