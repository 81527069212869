import { useState } from 'react';
import { EVENT_NAMES, sendEvent } from '../../utils/analytics';
import publicRuntimeConfig from '../../utils/config';
import useOnMountEffect from '../../utils/hooks/useOnMountEffect';
import SimpleModal from './modals/SimpleModal';

const UnsupportedBrowserModal = () => {
  const [showModal, setShowModal] = useState(false);

  useOnMountEffect(() => {
    if (publicRuntimeConfig.SUPPORTED_BROWSERS) {
      const { flags, source } = publicRuntimeConfig.SUPPORTED_BROWSERS;
      const supportedBrowsers = new RegExp(source, flags);
      if (!supportedBrowsers.test(navigator.userAgent)) {
        sendEvent({
          name: EVENT_NAMES.unsupportedBrowser,
          properties: {
            userAgent: navigator.userAgent,
          },
        });
      }
    }
    // only show modal on browser we explicitely don't support
    if (publicRuntimeConfig.UNSUPPORTED_BROWSERS) {
      const { flags, source } = publicRuntimeConfig.UNSUPPORTED_BROWSERS;
      const unsupportedBrowsers = new RegExp(source, flags);
      if (unsupportedBrowsers.test(navigator.userAgent)) {
        setShowModal(true);
      }
    }
  });

  if (!showModal) {
    return null;
  }
  return (
    <SimpleModal
      open
      header={'Upgrade browser for best experience'}
      body={'You can continue anyway, but we can’t promise that everything will work as intended.'}
      onClose={() => setShowModal(false)}
    />
  );
};

export default UnsupportedBrowserModal;
