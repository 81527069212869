import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeImageLinkSkeleton } from './TypeImageLink';
import type { TypeLinkSkeleton } from './TypeLink';

export interface TypeLinkGroupFields {
  identifier?: EntryFieldTypes.Symbol;
  title: EntryFieldTypes.Symbol;
  titleLink?: EntryFieldTypes.EntryLink<TypeLinkSkeleton>;
  links?: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<TypeImageLinkSkeleton | TypeLinkGroupSkeleton | TypeLinkSkeleton>
  >;
}

export type TypeLinkGroupSkeleton = EntrySkeletonType<TypeLinkGroupFields, 'linkGroup'>;
export type TypeLinkGroup<
  Modifiers extends ChainModifiers = 'WITHOUT_UNRESOLVABLE_LINKS',
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypeLinkGroupSkeleton, Modifiers, Locales>;

export function isTypeLinkGroup<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeLinkGroup<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'linkGroup';
}
