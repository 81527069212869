import styled from '@emotion/styled';
import { forwardRef, MutableRefObject, ReactNode } from 'react';
import { useResizeHeight } from '../../../../utils/hooks/useResizeHeight';
import { NAVBAR_HEIGHT_L, colors, spacing } from '../../../../utils/styleguide';

type ContainerProps = {
  isOpened?: boolean;
  height?: number;
};

const Container = styled.div`
  position: absolute;
  top: ${NAVBAR_HEIGHT_L}px;
  left: 0;
  height: calc(100vh - ${NAVBAR_HEIGHT_L}px);
  width: 100%;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  background: ${colors.white};

  transform: ${({ isOpened }: ContainerProps) =>
    isOpened ? 'translateX(0)' : 'translateX(-100%)'};
  transition: transform 0.3s ease-in-out;

  padding: ${spacing[4]}px;
  overflow-y: scroll;
`;

type MobileNavBarWrapperProps = {
  isOpened?: boolean;
  children: ReactNode;
  ref?: MutableRefObject<HTMLDivElement | null>;
  className?: string;
};

const MobileNavBarWrapper = forwardRef<HTMLDivElement, MobileNavBarWrapperProps>(
  function MobileNavBarWrapper({ isOpened, children, className }, ref) {
    const height = useResizeHeight();

    return (
      <Container
        data-testid="mobilenavbarwrapper"
        isOpened={isOpened}
        ref={ref}
        className={className}
        height={height}
      >
        {children}
      </Container>
    );
  },
);

export default MobileNavBarWrapper;
