import { css } from '@emotion/react';
import styled from '@emotion/styled';
import isNumber from 'lodash/isNumber';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { spacing } from '../../../utils/styleguide';
import IconButton from '../buttons/IconButton';
import Typography from '../text/Typography';

const ModalWrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 16px;
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  position: absolute;
`;

const ModalContent = styled.div`
  width: 100%;
  max-width: 532px;
  background-color: white;
  max-height: 80vh;
  overflow: hidden;
  box-shadow: 0 5px 22px rgba(0, 0, 0, 0.12), 0 12px 17px rgba(0, 0, 0, 0.14),
    0 7px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${spacing[5]}px;
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ModalBody = styled.div<{ centerContent?: boolean; padding?: number }>`
  overflow: auto;
  ${({ padding }) =>
    isNumber(padding)
      ? css`
          padding: ${padding}px;
        `
      : null};
  ${({ centerContent }) =>
    centerContent
      ? css`
          margin: 'auto';
        `
      : null}
`;

type Props = {
  header?: string | JSX.Element;
  body?: string | JSX.Element;
  onClose?: () => void;
  centerContent?: boolean;
  open?: boolean;
  fullHeight?: boolean;
  hideToolbar?: boolean;
  padding?: number;
  labelId?: string;
};

const SimpleModal = ({
  open,
  header,
  body,
  centerContent,
  onClose,
  fullHeight = false,
  hideToolbar = false,
  padding,
  labelId = 'dialog-title',
}: Props) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  if (!open) {
    return;
  }
  return createPortal(
    <ModalWrapper>
      <Overlay onClick={onClose} />
      <ModalContent
        css={fullHeight ? { height: '100%' } : {}}
        aria-modal
        role="dialog"
        aria-labelledby={labelId}
        tabIndex={-1}
      >
        {(header || onClose) && (
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              margin-bottom: ${spacing[5]}px;
            `}
          >
            <div
              css={css`
                flex: 1;
              `}
            >
              {typeof header === 'string' ? (
                <Typography id="dialog-title" variant="h4">
                  {header}
                </Typography>
              ) : (
                header
              )}
            </div>
            {onClose && !hideToolbar && (
              <CloseButtonWrapper>
                <IconButton
                  iconName="x-ol"
                  stroke="black"
                  ariaLabel="close"
                  onClick={onClose}
                  variant="onlyIcon"
                  css={{ cursor: 'pointer', marginRight: -spacing[3], marginTop: -spacing[3] }}
                />
              </CloseButtonWrapper>
            )}
          </div>
        )}
        <ModalBody centerContent={centerContent} padding={padding}>
          {typeof body === 'string' ? <Typography useBuiltInMargins>{body}</Typography> : body}
        </ModalBody>
      </ModalContent>
    </ModalWrapper>,
    document.body,
  );
};

export default SimpleModal;
