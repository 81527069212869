import { ChevronDown } from '@carbon/icons-react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { memo, useEffect, useState } from 'react';
import { breakpoints, colors, spacing, transitionSpeeds } from '../../../../../utils/styleguide';
import { HandleNavItemSelected } from '../../../../contentful-elements/navigationbar/common';
import Typography, { variants } from '../../../text/Typography';
import { NavLinkItemType } from '../../navTypes';

const LinkGroupButton = styled.button<{ color?: string }>`
  ${variants.buttonSmall.css}
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  color: ${({ color }) => color};

  svg {
    transition: transform ${transitionSpeeds.complex};
    transition-timing-function: cubic-bezier(0.54, 0, 0.2, 1.16);
  }

  svg.new-color {
    fill: ${({ color }) => color};
  }

  & .opened svg {
    transform: rotate(-180deg);
  }
`;

const ButtonContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing[0]}px;
`;

const Line = styled.div<{ color?: string }>`
  position: absolute;
  bottom: -${spacing[0]}px;
  left: 0;
  border-bottom: 1px solid ${({ color }) => color ?? colors.black};
  width: 0%;
  transition: width ${transitionSpeeds.complex};
  transition-timing-function: cubic-bezier(0.54, 0, 0.2, 1.16);

  ${LinkGroupButton}:hover & {
    width: 100%;
  }
`;

const LinkGroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing[1]}px;
  margin-bottom: ${spacing[4]}px;

  ${breakpoints.desktop} {
    flex-direction: row;
    gap: ${spacing[6]}px;
    justify-content: space-between;
    margin: 0 ${spacing[6]}px;
  }
`;

const LinkText = styled(Typography)<{ color?: string }>`
  display: flex;
  align-items: center;
  color: ${({ color }) => color || colors.black};
  cursor: pointer;
  justify-content: space-between;

  ${breakpoints.desktop} {
    display: flex;
    align-items: center;
  }

  &:hover {
    color: ${colors.black};
  }

  & a {
    ${({ color }: { color?: string }) => `color: ${color || colors.black};`}
    text-decoration: none;
  }
`;

type LinkGroupsProps = {
  linkGroups?: NavLinkItemType[];
  handleNavItemClick: HandleNavItemSelected;
  isMenuOpened: boolean;
  textColor?: string;
};

const LinkGroups = ({
  linkGroups,
  handleNavItemClick,
  isMenuOpened,
  textColor,
}: LinkGroupsProps) => {
  const [openedLinkGroup, setOpenedLinkGroup] = useState<string | null>(null);

  useEffect(() => {
    if (!isMenuOpened) {
      setOpenedLinkGroup(null);
    }
  }, [isMenuOpened]);

  return (
    <LinkGroupsWrapper>
      {linkGroups?.map(({ titleLink, title, id }) => (
        <LinkText key={id} color={colors.blackPrimary} variant="buttonSmall" tag="div">
          {titleLink ? (
            <Link
              style={{ color: textColor }}
              href={titleLink || '/'}
              passHref
              onClick={() => handleNavItemClick({ id })}
            >
              {title}
            </Link>
          ) : (
            <LinkGroupButton
              color={textColor}
              onClick={() => {
                handleNavItemClick({ id });
                setOpenedLinkGroup(id);
              }}
            >
              <>
                <ButtonContentWrapper className={id === openedLinkGroup ? 'opened' : undefined}>
                  {title} <ChevronDown />
                </ButtonContentWrapper>
                <Line />
              </>
            </LinkGroupButton>
          )}
        </LinkText>
      ))}
    </LinkGroupsWrapper>
  );
};

export default memo(LinkGroups);
