import {
  AUTHENTICATION_EVENT_NAMES,
  AUTHENTICATION_EVENT_PROPERTIES,
} from './authenticationEvents';
import { CONTACT_EVENT_NAMES, CONTACT_EVENT_PROPERTIES } from './contactEvents';
import { CREATE_POST_EVENT_NAMES, CREATE_POST_EVENT_PROPERTIES } from './createPostEvents';
import { GENERIC_EVENT_NAMES, GENERIC_EVENT_PROPERTIES } from './genericEvents';
import { IMPACTER_TOOLS_EVENT_NAMES, IMPACTER_TOOLS_EVENT_PROPERTIES } from './impacterToolsEvents';
import {
  PROGRESS_REPORTS_EVENT_NAMES,
  PROGRESS_REPORTS_EVENT_PROPERTIES,
} from './progressReportsEvents';
import { SUPPORT_EVENT_NAMES, SUPPORT_EVENT_PROPERTIES } from './supportEvents';
import { VIEW_EVENT_NAMES, VIEW_EVENT_PROPERTIES } from './viewEvents';
import { PPC_EVENT_NAMES, PPC_EVENT_PROPERTIES } from './ppcEvents';

export const EVENT_NAMES = {
  ...PROGRESS_REPORTS_EVENT_NAMES,
  ...SUPPORT_EVENT_NAMES,
  ...CREATE_POST_EVENT_NAMES,
  ...VIEW_EVENT_NAMES,
  ...AUTHENTICATION_EVENT_NAMES,
  ...GENERIC_EVENT_NAMES,
  ...IMPACTER_TOOLS_EVENT_NAMES,
  ...PPC_EVENT_NAMES,
  ...CONTACT_EVENT_NAMES,
} as const;

export type EVENT_PROPERTIES = PROGRESS_REPORTS_EVENT_PROPERTIES &
  SUPPORT_EVENT_PROPERTIES &
  CREATE_POST_EVENT_PROPERTIES &
  VIEW_EVENT_PROPERTIES &
  AUTHENTICATION_EVENT_PROPERTIES &
  GENERIC_EVENT_PROPERTIES &
  IMPACTER_TOOLS_EVENT_PROPERTIES &
  PPC_EVENT_PROPERTIES &
  CONTACT_EVENT_PROPERTIES;
