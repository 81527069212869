// Events for Impacter tools:

export const IMPACTER_TOOLS_EVENT_NAMES = {
  viewPayoutOverview: 'impacter tools - view payout overview',
  viewSinglePayout: 'impacter tools - view single payout',
  viewSupportOverview: 'impacter tools - view support overview',
  viewMonthFinancialDetails: 'impacter tools - view month financial details',
  viewProjectFiles: 'impacter tools - view project files',
  downloadFile: 'impacter tools - download file',
} as const;

export type IMPACTER_TOOLS_EVENT_PROPERTIES = {
  [IMPACTER_TOOLS_EVENT_NAMES.viewPayoutOverview]: undefined;
  [IMPACTER_TOOLS_EVENT_NAMES.viewSinglePayout]: undefined;
  [IMPACTER_TOOLS_EVENT_NAMES.viewSupportOverview]: undefined;
  [IMPACTER_TOOLS_EVENT_NAMES.viewMonthFinancialDetails]: undefined;
  [IMPACTER_TOOLS_EVENT_NAMES.viewProjectFiles]: undefined;
  [IMPACTER_TOOLS_EVENT_NAMES.downloadFile]: {
    initiator?: string;
    fileType?: string;
  };
};
