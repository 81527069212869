import styled from '@emotion/styled';
import { memo } from 'react';
import { breakpoints, NAVBAR_HEIGHT_L } from '../../../utils/styleguide';
import ContentfulNavBar from '../../contentful-elements/navigationbar/ContentfulNavBar';
import { NavBarClientProps } from '../../contentful-elements/util/navigation';

const Layout = styled.div<Props>`
  top: 0;
  mix-blend-mode: normal;
  height: ${NAVBAR_HEIGHT_L}px;
  ${breakpoints.tablet} {
    height: ${NAVBAR_HEIGHT_L}px;
  }
`;

type Props = {
  floating?: boolean;
  initiallyTransparent?: boolean;
  navigationBar?: NavBarClientProps;
  className?: string;
  hideNavbarLogo?: boolean;
  partnerLogoSrc?: string | null;
};

const Header = ({
  initiallyTransparent,
  navigationBar,
  className,
  hideNavbarLogo,
  partnerLogoSrc,
}: Props) => {
  if (!navigationBar) {
    return null;
  }

  return (
    <Layout className={className}>
      {navigationBar && (
        <ContentfulNavBar
          navigationBar={navigationBar}
          initiallyTransparent={initiallyTransparent}
          hideNavbarLogo={hideNavbarLogo}
          partnerLogoSrc={partnerLogoSrc}
        />
      )}
    </Layout>
  );
};

export default memo(Header);
