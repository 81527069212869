import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeLinkSkeleton } from './TypeLink';

export interface TypeButtonFields {
  identifier: EntryFieldTypes.Symbol;
  label: EntryFieldTypes.Symbol;
  colorContext: EntryFieldTypes.Symbol<'purple' | 'white'>;
  variant: EntryFieldTypes.Symbol<'outline' | 'solid'>;
  size: EntryFieldTypes.Symbol<'large' | 'small'>;
  prompt?: EntryFieldTypes.Symbol;
  trailingIconName?: EntryFieldTypes.Symbol<'arrow-right'>;
  leadingIconName?: EntryFieldTypes.Symbol<'arrow-left'>;
  href?: EntryFieldTypes.Symbol;
  link?: EntryFieldTypes.EntryLink<TypeLinkSkeleton>;
}

export type TypeButtonSkeleton = EntrySkeletonType<TypeButtonFields, 'button'>;
export type TypeButton<
  Modifiers extends ChainModifiers = 'WITHOUT_UNRESOLVABLE_LINKS',
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypeButtonSkeleton, Modifiers, Locales>;

export function isTypeButton<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeButton<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'button';
}
