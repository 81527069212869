// Auth
import isBrowser from './helpers/isBrowser';

const KEY = '@Auth:Token';
let jwtToken: string | null;

/**
 * Get the current authentication token
 * Could be expired, use only to make an optimistic assumption about login state
 * For example to link to a page that require authentication without actually checking the authentication.
 * Don't use it if following it with authenticated calls,
 * prefer wrapper the UI with AuthenticatedLayout and using the proper authentication selector
 */
export const getToken = () => {
  if (jwtToken) {
    return jwtToken;
  }
  if (!isBrowser()) {
    return;
  }
  jwtToken = localStorage.getItem(KEY);
  return jwtToken;
};

export const setToken = (token: string) => {
  jwtToken = token;
  localStorage.setItem(KEY, token);
};

export const removeToken = () => {
  jwtToken = null;
  localStorage.removeItem(KEY);
};
