import styled from '@emotion/styled';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from '../../../utils/hooks/useMediaQuery';
import { breakpointQueries } from '../../../utils/styleguide';
import LogoBrandIcon from '../../component-library/LogoBrandIcon';
import { NavItemType, NavLinkItemType } from '../../component-library/navigation/navTypes';
import { NavBarClientProps } from '../util/navigation';
import ActionNavBarButton from './ActionNavBarButton';
import Image from '../../component-library/Image';

function parseNavBarFields({
  imagePortrait,
  navButton,
  mobileBottomLinks,
  linkGroups,
  logoGroup,
  poweredByMilkywire,
  textColor,
}: NavBarClientProps) {
  return {
    imagePortrait,
    navButton: navButton ? <ActionNavBarButton button={navButton} /> : null,
    mobileBottomLinks,
    linkGroups,
    logoGroup,
    poweredByMilkywire,
    textColor,
  };
}

export type Props = {
  navigationBar: NavBarClientProps;
  initiallyTransparent?: boolean;
  hideNavbarLogo?: boolean;
  partnerLogoSrc?: string | null;
};

export const useNavBar = ({ navigationBar }: { navigationBar: Props['navigationBar'] }) => {
  const [isTabletOrDesktop] = useMediaQuery(breakpointQueries.tablet);
  const [submenu, setSubmenu] = useState<NavLinkItemType | null | undefined>(null);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setSubmenu(null);
  }, [router.query]);

  useEffect(() => {
    if (isMenuOpened && !isTabletOrDesktop) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpened, isTabletOrDesktop]);

  const { navButton, linkGroups, mobileBottomLinks, logoGroup, poweredByMilkywire, textColor } =
    useMemo(() => parseNavBarFields(navigationBar), [navigationBar]);

  const handleNavItemSelected = useCallback(
    ({ id }: { id?: string }) => {
      if (!id) {
        setSubmenu(null);
        return;
      }

      const link = linkGroups.find((link: NavLinkItemType) => link.id === id);

      if (!link) {
        return;
      }

      if (link.titleLink) {
        setIsMenuOpened(false);
        setSubmenu(null);
        return;
      }

      if (!submenu || submenu.id !== id) {
        setSubmenu(linkGroups.find((link: NavLinkItemType) => link.id === id));
        return;
      }

      setSubmenu(null);
    },
    [linkGroups, submenu, setIsMenuOpened],
  );

  const clearSubmenu = useCallback(() => {
    setSubmenu(null);
  }, [setSubmenu]);

  return {
    linkGroups,
    navButton,
    handleNavItemSelected,
    submenu,
    clearSubmenu,
    mobileBottomLinks,
    isMenuOpened,
    setIsMenuOpened,
    logoGroup,
    poweredByMilkywire,
    textColor,
  };
};

export type HandleNavItemSelected = ReturnType<typeof useNavBar>['handleNavItemSelected'];

const Navigation = styled.div`
  pointer-events: auto;
`;

const GoHomeLink = styled.a`
  pointer-events: auto;
  display: flex;
  text-decoration: none;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const LogosWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledImage = styled(Image)`
  max-height: 50px;
`;

export const Home = ({
  logoGroup,
  textColor,
  handleNavItemClick,
}: {
  logoGroup?: NavLinkItemType;
  textColor: string;
  handleNavItemClick: HandleNavItemSelected;
}) => {
  if (logoGroup?.links?.length) {
    return (
      <Wrapper>
        <LogosWrapper>
          {logoGroup?.links
            ?.filter((link): link is NavItemType => 'assetProps' in link || 'href' in link)
            ?.map(({ title, assetProps, href }, index) => {
              return href ? (
                <Navigation key={index}>
                  <Link href={href} key={index} aria-label={title}>
                    {assetProps && (
                      <StyledImage
                        fileName={assetProps.fileName}
                        url={assetProps.url}
                        disableContentfulScaling
                        alt={assetProps.alt}
                      />
                    )}
                  </Link>
                </Navigation>
              ) : (
                <Navigation key={index}>
                  {assetProps && (
                    <StyledImage
                      fileName={assetProps.fileName}
                      url={assetProps.url}
                      disableContentfulScaling
                      alt={assetProps.alt}
                    />
                  )}
                </Navigation>
              );
            })}
        </LogosWrapper>
      </Wrapper>
    );
  }

  return (
    <Navigation>
      <Link href={'/'} passHref legacyBehavior>
        <GoHomeLink aria-label="go-home" onClick={handleNavItemClick as MouseEventHandler}>
          <LogoBrandIcon color={textColor} height={32} />
        </GoHomeLink>
      </Link>
    </Navigation>
  );
};
