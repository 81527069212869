import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { DATE_FNS_LOCALES } from '../contentfulMicrocopy';
import { Locale } from 'date-fns';

/**
 * @retuns the website locale to use (amongst the ones we provide)
 */
export default function useLocale() {
  const router = useRouter();
  const [locale, setLocale] = useState(router.locale ?? router.defaultLocale ?? 'en');

  useEffect(() => {
    setLocale(router.locale ?? router.defaultLocale ?? 'en');
  }, [router.locale, router.defaultLocale]);

  return locale;
}

/**
 * @returns the actual preferred user locale
 */
export function useUserLocale() {
  const locale = useLocale();
  const [userLocale, setUserLocale] = useState(locale);

  useEffect(() => {
    if (locale === 'en' && window.navigator.language) {
      // trust user browser to avoid US/UK/EU formatting issues
      return setUserLocale(navigator.language);
    }
  }, [locale]);

  return userLocale;
}

export function useDateFnsLocale(locale: string) {
  const localeCode = DATE_FNS_LOCALES[locale];
  const [dateFnsLocale, setDateFnsLocale] = useState<Locale>();

  useEffect(() => {
    async function loadLocale() {
      if (!localeCode) return;
      const dateFnslocale = await import(`date-fns/locale/${localeCode}/index.js`);
      setDateFnsLocale(dateFnslocale);
    }

    loadLocale();
  }, [localeCode]);

  return dateFnsLocale;
}
