import {
  Copyright,
  UppyFileExtended,
} from '../../../domains/impacter-tools/content-report/elements/types';

// Events for Create Post:

export const CREATE_POST_EVENT_NAMES = {
  viewCreatePost: 'create post - view create',
  viewManagePosts: 'create post - view manage posts',
  createPostStartPost: 'create post - start new post',
  createPostFileAdded: 'create post - file added',
  createPostFileRemoved: 'create post - file removed',
  createPostUploadStart: 'create post - upload start',
  createPostUploadSuccess: 'create post - upload success',
  createPostUploadRetry: 'create post - upload retry',
  createPostUploadError: 'create post - upload error',
  createPostPublishStarted: 'create post - publish started',
  createPostPublishSuccess: 'create post - publish succeeded',
  createPostPublishError: 'create post - publish error',
  createPostPostAdded: 'create post - post added',
  createPostGoBackToOldCreate: 'create post - go to old create',
} as const;

export type CREATE_POST_EVENT_PROPERTIES = {
  [CREATE_POST_EVENT_NAMES.viewCreatePost]: {
    mode?: string | string[];
  };
  [CREATE_POST_EVENT_NAMES.viewManagePosts]: {
    mode?: string | string[];
  };
  [CREATE_POST_EVENT_NAMES.createPostStartPost]: {
    postId: string;
  };
  [CREATE_POST_EVENT_NAMES.createPostFileAdded]: {
    file: UppyFileExtended;
  };
  [CREATE_POST_EVENT_NAMES.createPostFileRemoved]: {
    file: UppyFileExtended;
    reason: 'removed-by-user' | 'cancel-all';
  };
  [CREATE_POST_EVENT_NAMES.createPostUploadStart]: {
    uploadId: string;
    fileIDs: string[];
  };
  [CREATE_POST_EVENT_NAMES.createPostUploadSuccess]: {
    file: UppyFileExtended;
  };
  [CREATE_POST_EVENT_NAMES.createPostUploadRetry]: {
    fileID: string;
  };
  [CREATE_POST_EVENT_NAMES.createPostUploadError]: {
    file: UppyFileExtended;
    error: Error;
    progress?: { bytesUploaded: number; bytesTotal: number };
    sentryEventId?: string;
  };
  [CREATE_POST_EVENT_NAMES.createPostPublishStarted]: {
    postId: string;
  };
  [CREATE_POST_EVENT_NAMES.createPostPublishSuccess]: {
    postId: string;
    caption: string;
    scheduledDate: Date | undefined;
    copyright?: Copyright | null;
    files: UppyFileExtended[];
    nbrOfFiles: number;
    nbrOfVideos: number;
    posterPositionsSet: number;
    timeOfPostStarted: Date;
    timeOfPostPublishedPressed: Date;
    timeToCreatePostInSeconds: number;
  };
  [CREATE_POST_EVENT_NAMES.createPostPublishError]: {
    postId: string;
    sentryEventId?: string;
  };
  [CREATE_POST_EVENT_NAMES.createPostPostAdded]: {
    nbrOfActivePostsAfterPostAdded: number;
  };
  [CREATE_POST_EVENT_NAMES.createPostGoBackToOldCreate]: undefined;
};
