import styled from '@emotion/styled';
import sample from 'lodash/sample';
import { NextPage } from 'next';
import { memo, useEffect, useState } from 'react';
import PrimaryButton from '../common-components/component-library/buttons/PrimaryButton';
import Typography from '../common-components/component-library/text/Typography';
import { EVENT_NAMES, sendEvent } from '../utils/analytics';
import { breakpoints, colors, spacing } from '../utils/styleguide';

const Background = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.black};
  min-height: 100vh;
  padding: 0 ${spacing[4]}px;
  ${breakpoints.tablet} {
    padding: 0 ${spacing[10]}px;
  }
  ${breakpoints.desktop} {
    padding: 0 ${spacing[11]}px;
  }
`;

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing[4]}px;
  ${breakpoints.tablet} {
    gap: ${spacing[9]}px;
  }
  ${breakpoints.desktop} {
    gap: ${spacing[10]}px;
  }
`;

const FourOFour = styled(Typography)`
  font-size: 140px;
  font-weight: 300;
  line-height: 120px;
  letter-spacing: -0.02em;
  text-align: center;
  ${breakpoints.tablet} {
    font-size: 200px;
    line-height: 150px;
    text-align: left;
  }
  ${breakpoints.desktop} {
    font-size: 240px;
    line-height: 180px;
    text-align: left;
  }
`;

const MessageContainer = styled.div`
  margin-top: auto;
`;

const Message = styled(Typography)`
  margin-bottom: ${spacing[6]}px;
`;

const errorJokes = {
  404: [
    '🐋 Whale hello there, looks like you found the 404 page',
    'Looks like you’re tangled in our web 🕸🕷',
  ],
  anyError: [
    'Well...this is hawkward 🦅',
    'Looks like a monkey got to the cables at Milkywire HQ 🙈',
    '🐛 As much as we like bugs 🐞 you’d probably rather go:',
    '🐫 Don’t get the hump, just press the button to go:',
  ],
};

function generateErrorJoke(statusCode?: number): string {
  // FIXME this is not working with SSR as rendering is different between client and server
  return sample(
    statusCode == 404 ? errorJokes.anyError.concat(errorJokes[404]) : errorJokes.anyError,
  ) as string;
}

type Props = {
  statusCode?: number;
};

const _ErrorPage = ({ statusCode }: Props) => {
  const [errorJoke, setErrorJoke] = useState<string>();
  useEffect(() => {
    if (statusCode === 404) {
      // we want analytics data not bug report
      sendEvent({
        name: EVENT_NAMES.show404,
        properties: {
          url: window.location.href,
        },
      });
    }
    setErrorJoke(generateErrorJoke(statusCode));
  }, [statusCode]);

  return (
    <Background>
      <Layout>
        <FourOFour color={colors.white}>
          {statusCode === 404 ? statusCode : `${statusCode} error`}
        </FourOFour>
        <MessageContainer>
          <Message variant="h4" color={colors.white}>
            {errorJoke}
          </Message>
          <PrimaryButton href="/">Back to Home</PrimaryButton>
        </MessageContainer>
      </Layout>
    </Background>
  );
};

const ErrorPage: NextPage<Props> = memo(_ErrorPage);

export default ErrorPage;
