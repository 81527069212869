import { UppyFile } from '@uppy/core';

// Events for Progress Report flow:

export const PROGRESS_REPORTS_EVENT_NAMES = {
  viewProgressReports: 'progress reports - view reports',
  progressReportFilesAdded: 'progress reports - files added',
  progressReportFilesUploaded: 'progress reports - files uploaded',
  progressReportUploadStart: 'progress reports - upload start',
  progressReportUploadSuccess: 'progress reports - upload success',
  progressReportUploadError: 'progress reports - upload error',
  progressReportUploadRetry: 'progress reports - upload retry',
} as const;

export type PROGRESS_REPORTS_EVENT_PROPERTIES = {
  [PROGRESS_REPORTS_EVENT_NAMES.viewProgressReports]: {
    mode?: string | string[];
  };
  [PROGRESS_REPORTS_EVENT_NAMES.progressReportFilesAdded]: {
    files?: UppyFile[];
  };
  [PROGRESS_REPORTS_EVENT_NAMES.progressReportFilesUploaded]: {
    projectId: string;
    progressReportId: string;
    files?: UppyFile[];
    nbrOfFiles: number;
    timeOfReportStarted: Date;
    timeToUploadReportsInSeconds: number;
  };
  [PROGRESS_REPORTS_EVENT_NAMES.progressReportUploadStart]: {
    fileIDs: string[];
    uploadId: string;
  };
  [PROGRESS_REPORTS_EVENT_NAMES.progressReportUploadSuccess]: {
    file: UppyFile;
  };
  [PROGRESS_REPORTS_EVENT_NAMES.progressReportUploadError]: {
    file: UppyFile;
    error: Error;
  };
  [PROGRESS_REPORTS_EVENT_NAMES.progressReportUploadRetry]: {
    fileID: string;
  };
};
