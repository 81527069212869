import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeCategoryPageSkeleton } from './TypeCategoryPage';
import type { TypeFooterSkeleton } from './TypeFooter';
import type { TypeMetaHeaderSkeleton } from './TypeMetaHeader';
import type { TypeMicrocopySetSkeleton } from './TypeMicrocopySet';
import type { TypeNavigationBarSkeleton } from './TypeNavigationBar';
import type { TypePageSectionSkeleton } from './TypePageSection';
import type { TypePaymentOptionSkeleton } from './TypePaymentOption';

export interface TypeLandingPageFields {
  slug: EntryFieldTypes.Symbol;
  metaHeader?: EntryFieldTypes.EntryLink<TypeMetaHeaderSkeleton>;
  tags?: EntryFieldTypes.Array<
    EntryFieldTypes.Symbol<'Cause' | 'Give one' | 'Hidden' | 'Top level'>
  >;
  showKlarnaAttentionBanner?: EntryFieldTypes.Boolean;
  sections?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypePageSectionSkeleton>>;
  displayName?: EntryFieldTypes.Symbol;
  donationProgramId?: EntryFieldTypes.Symbol;
  affiliateName?: EntryFieldTypes.Symbol;
  affiliateDetails?: EntryFieldTypes.Boolean;
  campaignTag?: EntryFieldTypes.Symbol;
  category?: EntryFieldTypes.EntryLink<TypeCategoryPageSkeleton>;
  paymentOption?: EntryFieldTypes.EntryLink<TypePaymentOptionSkeleton>;
  prioritizedImpacterIds?: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
  thankYouPageOverride?: EntryFieldTypes.EntryLink<TypeLandingPageSkeleton>;
  donationInitiator?: EntryFieldTypes.Symbol;
  microconfigSetTemplate?: EntryFieldTypes.Symbol<
    | 'climate-transformation-fund'
    | 'green-game-jam'
    | 'green-game-jam-studio'
    | 'thank-you-page-smart-move'
  >;
  microconfigSets?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeMicrocopySetSkeleton>>;
  navigationBar?: EntryFieldTypes.EntryLink<TypeNavigationBarSkeleton>;
  footer?: EntryFieldTypes.EntryLink<TypeFooterSkeleton>;
  disableTrackingConsent?: EntryFieldTypes.Boolean;
  redirectPage?: EntryFieldTypes.EntryLink<TypeLandingPageSkeleton>;
  previewPathname?: EntryFieldTypes.Symbol;
}

export type TypeLandingPageSkeleton = EntrySkeletonType<TypeLandingPageFields, 'landingPage'>;
export type TypeLandingPage<
  Modifiers extends ChainModifiers = 'WITHOUT_UNRESOLVABLE_LINKS',
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypeLandingPageSkeleton, Modifiers, Locales>;

export function isTypeLandingPage<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeLandingPage<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'landingPage';
}
