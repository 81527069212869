import { useEffect, useState } from 'react';
import Hotjar from '../../common-components/component-library/tracking/Hotjar';
import AmplitudeTracking from './AmplitudeTracking';
import FacebookTracking from './FacebookTracking';
import GTMTracking from './GTMTracking';
import SentryTracking from './SentryTracking';
import UptimeTracking from './UptimeRumTracking';

const TRACKING_DELAY = 2000;

const Tracking = ({ hasCookieConsent = false }) => {
  const [hasLoaded, setHasLoad] = useState(false);

  useEffect(() => {
    let timeout: number, listening: boolean;

    const onLoad = () => {
      timeout = window.setTimeout(() => {
        setHasLoad(true);
      }, TRACKING_DELAY);
    };

    if (document.readyState === 'complete') {
      onLoad();
    } else {
      listening = true;
      window.addEventListener('load', onLoad);
    }

    return () => {
      timeout && clearTimeout(timeout);
      listening && window.removeEventListener('load', onLoad);
    };
  }, []);

  if (!hasLoaded) {
    return null;
  }

  const trackers: (({ hasCookieConsent }: { hasCookieConsent: boolean }) => JSX.Element | null)[] =
    [AmplitudeTracking, SentryTracking];

  if (hasCookieConsent) {
    trackers.push(FacebookTracking, GTMTracking, UptimeTracking, Hotjar);
  }

  return (
    <>
      {trackers.map((Tracker, i) => (
        <Tracker key={i} hasCookieConsent={hasCookieConsent} />
      ))}
    </>
  );
};

export default Tracking;
