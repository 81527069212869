import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { createPortal } from 'react-dom';
import { getFromEnterpriseBackend } from '../../../utils/api';
import { EXCLUDE_FROM_TRACKING_PATHS } from '../../../utils/constants/excludeFromTracking';
import { breakpoints, colors, spacing } from '../../../utils/styleguide';
import Tracking from '../../../utils/tracking/Tracking';
import PrimaryButton from '../buttons/PrimaryButton';
import StaticPageLink from '../navigation/LegacyStaticPageLink';
import Typography from '../text/Typography';

const TrackingManager = ({ disableTrackingConsent = false }) => {
  const [cookies, setCookie] = useCookies();
  const router = useRouter();
  const [isCrawler, setIsCrawler] = useState(false);

  useEffect(() => {
    const checkIfUserAgentIsCrawler = async () => {
      try {
        const result = await getFromEnterpriseBackend<{ matchedCrawler?: string }>(
          'handle-crawler',
        );
        setIsCrawler(!!result.matchedCrawler);
      } catch (_) {
        // do nothing, we assume that it is not a crawler if something fails, to collect tracking info rather than not
      }
    };
    checkIfUserAgentIsCrawler();
  }, []);

  const isPageExcludedFromTracking = EXCLUDE_FROM_TRACKING_PATHS.has(router.asPath);

  const showCookieConsentBanner =
    !isCrawler &&
    !isPageExcludedFromTracking &&
    !disableTrackingConsent &&
    !('cookie_consent' in cookies);

  const shouldTrack = !isCrawler && !isPageExcludedFromTracking;

  const hasCookieConsent = disableTrackingConsent || cookies.cookie_consent === true;

  return (
    <>
      {showCookieConsentBanner && (
        <TrackingConsentBanner
          setConsent={(value: boolean) => setCookie('cookie_consent', value, { path: '/' })}
        />
      )}
      {shouldTrack && <Tracking hasCookieConsent={hasCookieConsent} />}
    </>
  );
};

/** Only renders banner on client, because we are relying on the DOM document */
const TrackingConsentBanner = ({ setConsent }: { setConsent: (value: boolean) => void }) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => setIsClient(true), []);

  if (!isClient) return null;

  return createPortal(
    <div
      css={css`
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${colors.black};
        padding: 20px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 20;

        ${breakpoints.desktop} {
          flex-direction: row;
        }

        a {
          color: ${colors.white};
        }
      `}
    >
      <div
        css={css`
          max-width: 1200px;
        `}
      >
        <Typography useBuiltInMargins color={colors.white} variant="bodySmall">
          We use cookies to offer you a better experience and analyze site traffic. By pressing
          &quot;Accept cookies&quot;, you consent to our use of cookies. By pressing &quot;Necessary
          cookies&quot;, we will only set the cookies which are necessary for the site to function
          correctly{' '}
        </Typography>
        <Typography useBuiltInMargins color={colors.white} variant="bodySmall">
          For more information, please see our{' '}
          <StaticPageLink slug="cookie-policy" title="cookie policy." />
        </Typography>
      </div>
      <PrimaryButton
        variant="outline"
        color="white"
        onClick={() => setConsent(false)}
        label="Necessary&nbsp;cookies"
        css={css`
          margin-top: 20px;
          width: 100%;

          ${breakpoints.desktop} {
            width: auto;
            margin-top: 0;
            margin-left: ${spacing[5]}px;
          }
        `}
      />
      <PrimaryButton
        onClick={() => setConsent(true)}
        label="Accept&nbsp;cookies"
        css={css`
          margin-top: 20px;
          width: 100%;

          ${breakpoints.desktop} {
            width: auto;
            margin-top: 0;
            margin-left: ${spacing[5]}px;
          }
        `}
      />
    </div>,
    document.body,
  );
};

export default TrackingManager;
