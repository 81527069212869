import { Component, ErrorInfo, type PropsWithChildren } from 'react';
import ErrorPage from '../../pages/_error';
import { EVENT_NAMES, sendEvent } from '../../utils/analytics';
import { captureException, getScopeContext } from '../../utils/tracking/sentry/sentry';

type State = {
  error?: {
    statusCode?: number;
  };
};

type Props = PropsWithChildren<Record<string, unknown>>;

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Record<string, unknown>) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: unknown) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    captureException(error, getScopeContext({ errorInfo }));
    sendEvent({
      name: EVENT_NAMES.clientError,
      properties: {
        error,
        errorInfo,
      },
    });
  }

  render() {
    if (this.state.error) {
      return <ErrorPage statusCode={this.state.error.statusCode} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
