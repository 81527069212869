// Events for Authentication:

import { SIGNING_METHODS } from '../../../types/AuthTypes';

export const AUTHENTICATION_EVENT_NAMES = {
  signUpViewPage: 'sign up - view sign up page',
  signUpAccountCreated: 'sign up - account created',
  signUpAccountFailed: 'sign up - account failed',
  loginViewPage: 'login - view login page',
  loginClickPasswordLoginButton: 'login - click password login button',
  loginLogin: 'login - login',
  loginLoginFailed: 'login - login failed',
  logout: 'logout',
  firebaseSigninRedirectSuccessful: 'firebase - sign-in redirect successful',
  firebaseSigninRedirectFailed: 'firebase - sign-in redirect failed',
  firebaseAccountCreatedSuccessful: 'firebase - account created successful',
  firebaseAccountCreatedFailed: 'firebase - account created failed',
  firebaseAuthStateChanged: 'firebase - auth state changed',
  loginWithWebFeedback: 'login with web feedback',
  checkSignUpEmailFailed: 'check signup email failed',
  createPasswordFailed: 'createPassword failed',
  viewStepCreatePassword: 'view step create password',
} as const;

export type AUTHENTICATION_EVENT_PROPERTIES = {
  [AUTHENTICATION_EVENT_NAMES.signUpViewPage]?: {
    initiator?: string;
  };
  [AUTHENTICATION_EVENT_NAMES.signUpAccountCreated]: {
    method: SIGNING_METHODS;
  };
  [AUTHENTICATION_EVENT_NAMES.signUpAccountFailed]: {
    method: SIGNING_METHODS;
    error: unknown;
  };
  [AUTHENTICATION_EVENT_NAMES.loginViewPage]?: {
    initiator?: string;
  };
  [AUTHENTICATION_EVENT_NAMES.loginClickPasswordLoginButton]: undefined;
  [AUTHENTICATION_EVENT_NAMES.loginLogin]: {
    method: SIGNING_METHODS;
  };
  [AUTHENTICATION_EVENT_NAMES.loginLoginFailed]: {
    method: SIGNING_METHODS;
    error: unknown;
  };
  [AUTHENTICATION_EVENT_NAMES.logout]: undefined;
  [AUTHENTICATION_EVENT_NAMES.firebaseSigninRedirectSuccessful]: undefined;
  [AUTHENTICATION_EVENT_NAMES.firebaseSigninRedirectFailed]: {
    error: unknown;
  };
  [AUTHENTICATION_EVENT_NAMES.firebaseAccountCreatedSuccessful]: {
    method: SIGNING_METHODS;
  };
  [AUTHENTICATION_EVENT_NAMES.firebaseAccountCreatedFailed]: {
    method: SIGNING_METHODS;
    error: unknown;
  };
  [AUTHENTICATION_EVENT_NAMES.firebaseAuthStateChanged]: {
    signedIn?: boolean;
    error?: Error;
  };
  [AUTHENTICATION_EVENT_NAMES.loginWithWebFeedback]: {
    choice: 'thumbs-up' | 'thumbs-down';
  };
  [AUTHENTICATION_EVENT_NAMES.checkSignUpEmailFailed]: {
    error: unknown;
  };
  [AUTHENTICATION_EVENT_NAMES.createPasswordFailed]: {
    error: unknown;
  };
  [AUTHENTICATION_EVENT_NAMES.viewStepCreatePassword]: undefined;
};
