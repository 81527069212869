import styled from '@emotion/styled';
import Link from 'next/link';
import { colors, spacing } from '../../../../utils/styleguide';
import { colorsKurppa } from '../../../../utils/styleguideKurppa';
import Typography from '../../text/Typography';
import { NavItemType } from '../navTypes';

const PolicyLinks = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${spacing[3]}px;

  a + a {
    padding-left: ${spacing[2]}px;
    border-left: 2px solid ${colorsKurppa.grey03};
  }
`;

const SecondaryLinkText = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

type Props = {
  policyLinks?: NavItemType[];
};

const PolicyLinksAndInfo = ({ policyLinks }: Props) => {
  if (!policyLinks?.length) {
    return null;
  }

  return (
    <PolicyLinks>
      {policyLinks.map(({ title, content, href }, index) => {
        if (!href) {
          return null;
        }
        return (
          <Link key={index} href={href} passHref={!!href} legacyBehavior>
            <SecondaryLinkText aria-label={title}>
              <Typography
                variant="overlineSmall"
                color={colors.blackSecondary}
                hoverColor={colors.blackPrimary}
                tag="span"
              >
                {content}
              </Typography>
            </SecondaryLinkText>
          </Link>
        );
      })}
    </PolicyLinks>
  );
};

export default PolicyLinksAndInfo;
