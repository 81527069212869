// Read more about dynamic microcopy here: https://www.contentful.com/help/dynamic-microcopy/

import { EntryCollection } from 'contentful';
import { createContext, useContext } from 'react';
import { isTypeMicrocopySet, TypeMicrocopySetSkeleton } from '../../@types/generated';

export const microcopySet = {
  PAYMENT_OPTION: 'paymentOption',
  TEXT_INPUT: 'textInput',
  KLARNA_ATTENTION_BANNER: 'klarnaAttentionBanner',
  ARTICLES_PAGE: 'articlesPage',
} as const;

type TypeMicrocopySet = typeof microcopySet;
type MicrocopySet = TypeMicrocopySet[keyof TypeMicrocopySet];

interface Microcopy {
  [key: string]: string;
}

export interface MicrocopySets {
  paymentOption: Microcopy;
  textInput: Microcopy;
  klarnaAttentionBanner?: Microcopy;
  articlesPage: Microcopy;
  projectListingPage: Microcopy;
  projectPage: Microcopy;
}

/**
 * This is used to determine the microcopy keys which are fetched for all template pages.
 * See util/template.tsx for details/usage
 */
export const microcopyGlobalKeys: MicrocopySet[] = [
  microcopySet.TEXT_INPUT,
  microcopySet.KLARNA_ATTENTION_BANNER,
];

/**
 * Default values of this initial microcopy are left for components that are not part of a
 * translated page, so that they can still be rendered with their proper text.
 *
 * Long term we shouldn't need to have any default values here if the components are properly
 * represented in Contentful and if the translations are being used only where they are needed.
 */
const initialMicrocopy: MicrocopySets = {
  paymentOption: {
    cancelAnytime: 'Edit or cancel anytime',
    checkoutAnd: 'and',
    checkoutEmail: 'Email',
    checkoutPrivacy: 'Privacy Policy',
    checkoutTerms: 'Terms of Use',
    donate: 'Donate now',
    donationTerms: 'By making a donation, you agree to our',
    editAmount: 'edit amount',
    emailOptInLabel: 'Yes! I’d like to get the latest updates and news from Milkywire in my inbox.',
    giveOnce: 'Give once',
    giving: 'Giving',
    manualPaymentOptionLabel: 'or enter your details manually below',
    month: 'month',
    monthly: 'Give monthly',
    otherAmount: 'Other amount',
    minimumCustomAmount: 'The minimum amount is',
    maximumCustomAmount: 'The maximum amount is',
    securePayments: 'Only 100% secure payment providers.',
    subscriptionUserExists:
      'There already exists a user with this email. Please use the app to update your subscription',
  },
  textInput: {
    enterEmail: 'You need to enter an email address',
    enterFamilyName: 'You need to enter your last name',
    enterGivenName: 'You need to enter your first name',
    enterPassword: 'You need to enter a password',
    invalidEmail: "Hmm... That doesn't look like an email address",
    passwordLength: 'The password needs to be at least 6 characters',
    enterCompany: 'You need to enter your company name',
  },
  articlesPage: {
    heroTitle: 'Articles',
    heroCTALabel: 'Get in touch',
    heroCTALink: '/contact',
    loadMoreLabel: 'Load more',
  },
  projectListingPage: {
    title: 'Projects',
    description: 'Browse projects within the fund',
  },
  projectPage: {
    aboutTitle: 'About',
    pillarTitle: 'Pillar & approaches',
    mapTitle: 'Impact made to date',
    impacterTitle: 'Impacter(s)',
    updateTitle: 'Latest updates',
    projectListingTitle: 'Other projects within the fund',
    projectListingCTATitle: 'See all other projects',
    projectListingCTADescription: 'Browse all other projects within the fund',
    projectListingCTAButtonLabel: 'See all projects',
  },
};

export type GlobalKeys = (keyof typeof initialMicrocopy)[];

/**
 * Translation interface for the dynamic contentful microcopy.
 *
 * We use Contentful instead of an internal i18n solution for this to leverage the
 * power of the CMS and remove the dependency for a developer to manage even these
 * smaller mircocopy used in the app.
 */

export const MicrocopyContext = createContext<MicrocopySets | undefined>(undefined);
export const useMicrocopy = () => {
  const microcopy = useContext(MicrocopyContext) || initialMicrocopy;
  function t(key: MicrocopySet, value: string) {
    return microcopy[key]?.[value] ?? `${key}.${value}`;
  }
  return t;
};

export function formatMicrocopy(
  entries: EntryCollection<TypeMicrocopySetSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>,
): MicrocopySets {
  const contentfulMicrocopy: MicrocopySets = entries.items?.reduce(
    (set, item) => {
      if (!isTypeMicrocopySet(item)) return set;

      const microcopyValues = item.fields.resources?.reduce((acc, resource) => {
        if (resource) return { ...acc, [resource.fields.key]: resource.fields.value };

        return acc;
      }, {});
      return { ...set, [item.fields.name]: microcopyValues };
    },
    { ...initialMicrocopy },
  );
  return contentfulMicrocopy;
}

export const DATE_FNS_LOCALES: { [key: string]: string } = {
  sv: 'sv',
  de: 'de',
  no: 'nb',
  fr: 'fr',
  es: 'es',
  pt: 'pt',
};
