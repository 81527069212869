import { MutableRefObject, useEffect } from 'react';

export function useOutsideClick({
  containerRef,
  innerRef,
  onOutsideClick,
}: {
  containerRef?: MutableRefObject<HTMLDivElement | null>;
  innerRef: MutableRefObject<HTMLDivElement | null>;
  onOutsideClick: (event: Event) => void;
}) {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (innerRef.current && !innerRef.current.contains(event.target as HTMLDivElement)) {
        onOutsideClick(event);
      }
    }

    const container = containerRef?.current || document;

    container.addEventListener('mousedown', handleClickOutside);
    return () => {
      container.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick, innerRef, containerRef]);
}
