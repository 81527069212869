export const TRACKING_ID = 'GTM-PJR27K9';

type State = {
  queue: {
    name: string;
    event: unknown;
  }[];
};

export const state: State = {
  queue: [],
};

export const loadScript = () => {
  if (window.gtag) {
    return;
  }

  window.dataLayer = window.dataLayer || [];

  window.gtag = function gtag(...args) {
    window.dataLayer.push(args);
  };

  window.gtag('js', new Date());

  window.gtag('config', `${TRACKING_ID}`, { anonymize_ip: true, send_page_view: false });

  window.gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'granted',
  });

  state.queue.forEach(({ event }) => window.dataLayer?.push(event));
};
