import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';
import React from 'react';
import { colors, spacing } from '../../../utils/styleguide';
import Icon, { IconNames } from '../Icon';

const StyledButton = styled.button<{
  variant: 'filled' | 'onlyIcon';
  width?: number;
  height?: number;
  buttonColor?: string;
  hoverButtonColor?: string;
  showFocus: boolean;
}>`
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background: none;
  :disabled {
    cursor: default;
  }
  ${({ variant, width, height, buttonColor, hoverButtonColor, showFocus }) =>
    variant === 'filled'
      ? css`
          width: ${width || spacing[7]}px;
          height: ${height || spacing[7]}px;
          background-color: ${buttonColor || colors.black};

          :hover {
            background-color: ${hoverButtonColor || '#2c2e3a'};
          }

          ${showFocus
            ? css`
                :focus {
                  box-shadow: 0 0 0 2px ${colors.yellow}, 0 0 0 3px ${colors.black};
                }
              `
            : ''}
          :disabled {
            background-color: ${colors.lightGrey};
          }
        `
      : ''}
`;

type Props = {
  className?: string;
  iconName: IconNames;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?: 'filled' | 'onlyIcon';
  stroke?: string;
  fill?: string;
  ariaLabel: string;
  disabled?: boolean;
  iconSize?: number;
  desktopIconSize?: number;
  width?: number;
  height?: number;
  buttonColor?: string;
  hoverButtonColor?: string;
  showFocus?: boolean;
  href?: string;
  title?: string;
};

const IconButton = ({
  iconName,
  className,
  onClick,
  variant = 'filled',
  stroke,
  ariaLabel,
  disabled = false,
  iconSize,
  desktopIconSize,
  width,
  height,
  buttonColor,
  hoverButtonColor,
  fill,
  showFocus = true,
  href,
  title,
}: Props) => {
  let defaultColor = colors.black;
  if (variant === 'filled') {
    defaultColor = colors.white;
  }
  if (variant === 'onlyIcon' && disabled) {
    defaultColor = colors.lightGrey;
  }

  const isLink = href && !disabled;

  const button = (
    <StyledButton
      className={className}
      onClick={onClick}
      variant={variant}
      aria-label={ariaLabel}
      disabled={disabled}
      width={width}
      height={height}
      buttonColor={buttonColor}
      hoverButtonColor={hoverButtonColor}
      showFocus={showFocus}
      as={isLink ? 'a' : undefined}
      title={title}
    >
      <Icon
        name={iconName}
        size={iconSize || 24}
        desktopSize={desktopIconSize}
        stroke={stroke || defaultColor}
        fill={fill}
      />
    </StyledButton>
  );

  if (isLink) {
    return (
      <Link href={href as string} passHref legacyBehavior>
        {button}
      </Link>
    );
  }

  return button;
};

export default IconButton;
