import Link from 'next/link';
import { ReactNode } from 'react';

type Props = {
  slug: string;
  title?: string;
  children?: ReactNode;
};

const LegacyStaticPageLink = ({ slug, title, children }: Props) => (
  <Link href="/page/[slug]" as={`/page/${slug}`} passHref={!!children} legacyBehavior>
    {children ? children : <a aria-label="Page">{title}</a>}
  </Link>
);

export default LegacyStaticPageLink;
