import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { MAX_WIDTH } from '../../layout/12ColGridLayout';

const Wrapper = styled.nav`
  margin: 0 auto;
  padding: 0;
  max-width: ${MAX_WIDTH}px;
  width: inherit;
  top: 0;
  left: 0;
  right: 0;
  height: inherit;
  background-color: transparent;
`;

type Props = {
  children: ReactNode;
};

const StaticHeaderWrapper = ({ children }: Props) => {
  return (
    <Wrapper id="navbar" aria-label="Header">
      {children}
    </Wrapper>
  );
};

export default StaticHeaderWrapper;
