import useOnMountEffect from '../hooks/useOnMountEffect';
/* eslint-disable-next-line no-restricted-imports */
import { onLoaded } from './sentry/private/sentryBrowser';

const SentryTracking = () => {
  useOnMountEffect(() => {
    onLoaded();
  });
  return null;
};

export default SentryTracking;
