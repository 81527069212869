export type Color = keyof typeof colorsKurppa;

export const colorsKurppa = {
  blackPrimary: `#111111`,
  blackSecondary: `#111111B2`,
  blackDisabled: `#11111166`,

  whitePrimary: `#FFFFFF`,
  whiteSecondary: `#FFFFFFCC`,
  whiteDisabled: `#FFFFFF80`,

  grey01: `#F5F5F5`,
  grey02: `#E5E5E5`,
  grey03: `#CACACA`,
  grey04: `#373737`,
  grey50: `#FAFAFA`,

  orange100: `#FFB679`,
  orange200: `#FFA357`,
  orange300: `#FF8D3B`,
  orange400: `#FE811A`,
  orange500: `#FD7709`,
  orange600: `#EF6C00`,

  purple100: `#D9B9FF`,
  purple200: `#BF95F0`,
  purple300: `#8560DB`,
  purple400: `#663FBA`,
  purple500: `#5329A7`,
  purple600: `#3C0090`,
  transparentPurple: `#5329A714`, // 14 equals 8% transparency
  purpleLight: `#fdf7ff`,

  green100: '#8EE9C5',
  green200: '#3FCF9B',
  green300: '#29BE88',
  green400: '#196356',
  green500: '#005045',
  green600: '#00382F',

  red100: `#FFADA4`,
  red200: `#FF9A8E`,
  red300: `#FF6B67`,
  red400: `#FF5353`,
  red500: `#E22F35`,
  red600: `#BA1A20`,

  yellow100: '#FFDB93',
  yellow200: '#FFD27B',
  yellow300: '#FFC453',
  yellow400: '#FFBA35',
  yellow500: '#FFAE11',
  yellow600: '#F09E00',
};
