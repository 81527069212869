// Events for Support flow

import { PaymentRequestEvent } from '@stripe/stripe-js';
import { TypePaymentOption } from '../../../../@types/generated';
import { FlowType, PaymentType, Provider, SubscriptionFlowType } from '../../../types/PaymentTypes';

type AMOUNT_PROPERTIES = {
  amount: number | undefined;
  'currency code': string | undefined;
  'payment provider'?: Provider;
};

export const SUPPORT_EVENT_NAMES = {
  supportSuccessful: 'support - support successful',
  supportFailed: 'support - support failed',
  supportClickCancel: 'support - click cancel',
  supportClickSupport: 'support - click support',
  supportClickConfirm: 'support - click confirm support',
  supportChangePeriod: 'support - change period',
  supportClickConfirmPeriod: 'support - click confirm period',
  supportClickConfirmReceiverInformation: 'support - click confirm receiver information',
  supportEnterThankYouPage: 'support - enter thank you page',
  supportEnterThankYouPageSmartMove: 'support - enter thank you page smart move',
  supportThankYouPageSmartMoveClickedCTA: 'support - thank you page smart move clicked CTA',
  supportThankYouPageSmartMoveVideoFeedback: 'support - thank you page smart move video feedback',
  startPaymentFlow: 'support - start payment flow',
  paymentManagementAddCard: 'payment management - add card',
  paymentManagementChangeDefaultCard: 'payment management - change default card',
  paymentManagementClickDeleteCard: 'payment management - click delete card',
  supportChangePaymentType: 'support - change payment type',
  supportDonationChange: 'support - donation change',
  supportPaymentIntentFailed: 'support - payment intent failed',
  supportPaymentIntentSuccess: 'support - payment intent success',
  supportProceedToPaymentMethodSelection: 'support - proceed to payment method selection',
  supportAvailableWallets: 'support - available wallets',
  supportInitiatePayment: 'support - initiate payment',
  manageDonation: 'donation - manage donation',
  selectContributionCategory: 'contribution - select contribution category',
} as const;

export type SUPPORT_EVENT_PROPERTIES = {
  [SUPPORT_EVENT_NAMES.supportSuccessful]: AMOUNT_PROPERTIES & {
    'impacter ids'?: (string | number)[];
    'impacter names'?: (string | number)[];
    'support type': PaymentType;
    'flow type'?: FlowType | SubscriptionFlowType;
    'payment flow id'?: string;
    'payment method': PaymentRequestEvent['walletName'];
    'tag names'?: (string | number)[];
    initiator?: string;
    campaign?: string;
    paymentRequestEnabled?: boolean;
  };
  [SUPPORT_EVENT_NAMES.supportFailed]: AMOUNT_PROPERTIES & {
    'impacter ids'?: (string | number)[];
    'impacter names'?: (string | number)[];
    'support type': PaymentType;
    'flow type'?: FlowType | SubscriptionFlowType;
    'payment flow id'?: string;
    'payment method': PaymentRequestEvent['walletName'];
    'tag names'?: (string | number)[];
    error: unknown;
    paymentRequestEnabled?: boolean;
  };
  [SUPPORT_EVENT_NAMES.supportClickCancel]: AMOUNT_PROPERTIES & {
    'impacter ids'?: (string | number)[];
    'impacter names'?: (string | number)[];
    'support type': PaymentType;
    'flow type'?: FlowType | SubscriptionFlowType;
    'payment flow id'?: string;

    'tag names'?: (string | number)[];
  };
  [SUPPORT_EVENT_NAMES.supportClickSupport]: {
    initiator?: string;
    campaign?: string;
    provider?: Provider;
    'impacter ids'?: Array<string | number>;
    'impacter names'?: string[];
    'project id'?: number;
    'support type': PaymentType | undefined;
    'flow type': FlowType | SubscriptionFlowType | undefined;
    'payment flow id'?: string;
    'category id'?: string;
    'category name'?: string;
    'tag names'?: (string | number)[];
    'amount configuration'?:
      | TypePaymentOption['fields']['oneTimeAmountConfiguration']
      | TypePaymentOption['fields']['subscriptionAmountConfiguration'];
  };
  [SUPPORT_EVENT_NAMES.supportClickConfirm]: AMOUNT_PROPERTIES & {
    'impacter ids'?: (string | number)[];
    'impacter names'?: (string | number)[];
    'support type': PaymentType | undefined;
    'flow type'?: FlowType | SubscriptionFlowType;
    'payment flow id'?: string;
    'payment method': PaymentRequestEvent['walletName'];
    'tag names'?: (string | number)[];
    initiator?: string;
    campaign?: string;
    paymentRequestEnabled?: boolean;
  };
  [SUPPORT_EVENT_NAMES.supportChangePeriod]: {
    'support type': PaymentType;
    'flow type'?: FlowType | SubscriptionFlowType;
    'payment flow id'?: string;
    period?: number;
    'currency code'?: string;
  };
  [SUPPORT_EVENT_NAMES.supportClickConfirmPeriod]: {
    'support type': PaymentType;
    'flow type'?: FlowType | SubscriptionFlowType;
    'payment flow id'?: string;
    period?: number;
    amount?: number;
    'currency code'?: string;
  };
  [SUPPORT_EVENT_NAMES.supportClickConfirmReceiverInformation]: {
    'support type': PaymentType;
    'flow type'?: FlowType | SubscriptionFlowType;
    'payment flow id'?: string;
    hasMessage?: boolean;
    'currency code'?: string;
  };
  [SUPPORT_EVENT_NAMES.supportEnterThankYouPage]: {
    'flow type'?: FlowType | SubscriptionFlowType;
    'payment flow id'?: string;
  };
  [SUPPORT_EVENT_NAMES.supportEnterThankYouPageSmartMove]: {
    'flow type'?: FlowType | SubscriptionFlowType;
    slug: string;
  };
  [SUPPORT_EVENT_NAMES.supportThankYouPageSmartMoveClickedCTA]: {
    'flow type'?: FlowType | SubscriptionFlowType;
    slug: string;
  } & (
    | {
        type: 'receipt';
      }
    | { type: 'post-video'; videoId: number }
  );
  [SUPPORT_EVENT_NAMES.supportThankYouPageSmartMoveVideoFeedback]: {
    'flow type'?: FlowType | SubscriptionFlowType;
    slug: string;
    feedback: 'thumbs-up' | 'thumbs-down';
    videoId: number;
  };
  [SUPPORT_EVENT_NAMES.startPaymentFlow]: {
    initiator?: string;
    'impacter ids'?: number[];
    'impacter names'?: string[];
    'project id'?: number;
    'support type': PaymentType;
    'flow type'?: FlowType | SubscriptionFlowType;
    'payment flow id'?: string;
  };
  [SUPPORT_EVENT_NAMES.paymentManagementAddCard]: {
    'payment provider': 'stripe';
    'payment method': PaymentRequestEvent['walletName'];
    'payment flow id'?: string;
  };
  [SUPPORT_EVENT_NAMES.paymentManagementChangeDefaultCard]: {
    'payment provider': 'stripe';
    'payment method': PaymentRequestEvent['walletName'];
    'payment flow id'?: string;
  };
  [SUPPORT_EVENT_NAMES.paymentManagementClickDeleteCard]: {
    'payment provider': 'stripe';
    'payment method': PaymentRequestEvent['walletName'];
    'payment flow id'?: string;
  };
  [SUPPORT_EVENT_NAMES.supportChangePaymentType]: {
    campaign?: string;
    initiator: string;
    'payment flow id'?: string;
    'payment provider'?: Provider;
    'payment type': string;
  };
  [SUPPORT_EVENT_NAMES.supportDonationChange]: AMOUNT_PROPERTIES & {
    'support type': string;
    path: string;
    'change type': string;
    'flow type'?: FlowType | SubscriptionFlowType;
  };
  [SUPPORT_EVENT_NAMES.supportPaymentIntentFailed]: AMOUNT_PROPERTIES & {
    'support type': string;
    path: string;
    'flow type'?: FlowType | SubscriptionFlowType;
    'selected wallet': string;
    error: string;
  };
  [SUPPORT_EVENT_NAMES.supportPaymentIntentSuccess]: AMOUNT_PROPERTIES & {
    'support type': string;
    path: string;
    'flow type'?: FlowType | SubscriptionFlowType;
    'selected wallet': string;
  };
  [SUPPORT_EVENT_NAMES.supportProceedToPaymentMethodSelection]: AMOUNT_PROPERTIES & {
    'support type': string;
    path: string;
    'flow type'?: FlowType | SubscriptionFlowType;
  };
  [SUPPORT_EVENT_NAMES.supportAvailableWallets]: AMOUNT_PROPERTIES & {
    'support type': string;
    path: string;
    'flow type'?: FlowType | SubscriptionFlowType;
    'available wallets': PaymentRequestEvent['walletName'][] | string[];
  };
  [SUPPORT_EVENT_NAMES.supportInitiatePayment]: AMOUNT_PROPERTIES & {
    'support type': string;
    path: string;
    'flow type'?: FlowType | SubscriptionFlowType;
    'selected wallet': string;
    'available wallets': PaymentRequestEvent['walletName'][] | string[];
  };
  [SUPPORT_EVENT_NAMES.manageDonation]: {
    'change type': 'boost' | 'reduce' | 'cancel';
    'new amount': number;
    'previous amount'?: number;
  };
  [SUPPORT_EVENT_NAMES.selectContributionCategory]: {
    'category id': string;
  };
};
