import ContentfulFooter, {
  ContentfulFooterProps,
} from '../../contentful-elements/footer/ContentfulFooter';

const Footer = ({ footerBar, className }: ContentfulFooterProps) => {
  if (!footerBar) {
    return null;
  }
  return <ContentfulFooter footerBar={footerBar} className={className} />;
};

export default Footer;
