import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { colors, spacing } from '../../utils/styleguide';
import Typography from './text/Typography';

const ContentWrapper = styled.div``;

const Prompt = styled(Typography)`
  width: 100%;
  margin: ${spacing[1]}px 0 0;
`;

type ButtonPromptWrapperProps = {
  children?: ReactNode;
  className?: string;
  prompt?: ReactNode | string;
  variant?: 'light' | 'dark';
};

const ButtonPromptWrapper = ({
  children,
  className,
  prompt,
  variant = 'light',
}: ButtonPromptWrapperProps) => {
  return (
    <ContentWrapper className={className}>
      {children}
      {prompt && (
        <Prompt variant="detail" color={variant === 'dark' ? colors.white : colors.darkGray}>
          {prompt}
        </Prompt>
      )}
    </ContentWrapper>
  );
};

export default ButtonPromptWrapper;
