import {
  addToCart,
  checkout,
  GTM_EVENTS,
  pageView,
  productClick,
  productView,
  purchase,
} from './gtmEvents';

export function sendGTMEvent(...args: GTM_EVENTS) {
  const [eventName] = args;
  switch (eventName) {
    case 'purchase': {
      purchase(args[1]);
      break;
    }
    case 'checkout': {
      checkout(args[1]);
      break;
    }
    case 'addToCart': {
      addToCart(args[1]);
      break;
    }
    case 'productView': {
      productView(args[1]);
      break;
    }
    case 'productClick': {
      productClick(args[1]);
      break;
    }
    case 'pageView': {
      pageView();
      break;
    }
    default:
      return;
  }
}
