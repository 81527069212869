// Events for Viewing:

type VIEW_VIDEO_COMMON = {
  'impacter name'?: string;
  'impacter id'?: number | string;
  'video id': number | string;
  'video type'?: // app types
  'intro' | 'post' | 'category' | 'onboarding' | 'updates empty state';
  'post id'?: number | string;
  'intro video index'?: number;
  location: string;
};

type UTM_TYPES = {
  'utm content'?: string | string[];
  'utm source'?: string | string[];
  'utm referrer'?: string | string[];
  'utm campaign'?: string | string[];
  'utm medium'?: string | string[];
};

export const VIEW_EVENT_NAMES = {
  viewHomePage: 'home - view home page',
  viewHomePageProxy: 'home - view home page - PROXY',
  viewPage: 'view page',
  viewCategory: 'view category',
  viewCampaign: 'view campaign',
  viewImpacter: 'view impacter',
  viewConfirmDonationPage: 'home - view confirm donation page',
  viewPostPage: 'view post page',
  viewVideo: 'view video',
  viewVideoCompleted: 'video viewed',
  viewImpacterFaq: 'impacter faq - view faq',
  viewImpacterTermsOfUse: 'impacter terms of use - view terms',
  viewInformationModal: 'view information modal',
  viewCreateAccount: 'view create account',
  viewEnterPassword: 'view enter password',
  viewImpacterTools: 'impacter tools - view impacter tools',
} as const;

export type VIEW_EVENT_PROPERTIES = {
  [VIEW_EVENT_NAMES.viewHomePage]: undefined;
  [VIEW_EVENT_NAMES.viewHomePageProxy]: undefined;
  [VIEW_EVENT_NAMES.viewCategory]: {
    'category name'?: string;
    'impacter ids'?: (string | number)[];
    'impacter names'?: (string | number)[];
  };
  [VIEW_EVENT_NAMES.viewPage]: UTM_TYPES & {
    country?: string | string[];
    language: string;
    referrer: string;
    path: string;
    'from path'?: string;
  };
  [VIEW_EVENT_NAMES.viewCampaign]: UTM_TYPES & {
    'campaign name': string | string[];
    'impacter ids'?: (string | number)[];
    'impacter names'?: (string | number)[];
    tags?: (string | number)[];
    'project id'?: number;
    'payment flow id'?: string;
    country?: string | string[];

    language?: string;
    referrer?: string;
    slug?: string;
    categoryId?: string;
  };
  [VIEW_EVENT_NAMES.viewImpacter]: {
    'impacter id'?: number;
    'impacter name'?: string;
    'country parameter'?: string;
    provider?: 'stripe' | 'klarna';
  };
  [VIEW_EVENT_NAMES.viewConfirmDonationPage]: undefined; // ELIN TODO
  [VIEW_EVENT_NAMES.viewVideo]: VIEW_VIDEO_COMMON;
  [VIEW_EVENT_NAMES.viewPostPage]: {
    postId: string;
  };
  [VIEW_EVENT_NAMES.viewVideoCompleted]: VIEW_VIDEO_COMMON & {
    'total video duration in seconds': number;
    'watched video duration in seconds': number;
    'watched video percentage': number; // this is actually a ratio, not a %
  };
  [VIEW_EVENT_NAMES.viewImpacterFaq]: {
    mode?: string | string[];
  };
  [VIEW_EVENT_NAMES.viewImpacterTermsOfUse]: {
    mode?: string | string[];
  };
  [VIEW_EVENT_NAMES.viewInformationModal]: {
    path: string;
    title: string;
    label: string;
  };
  [VIEW_EVENT_NAMES.viewCreateAccount]: undefined;
  [VIEW_EVENT_NAMES.viewEnterPassword]: undefined;
  [VIEW_EVENT_NAMES.viewImpacterTools]: {
    mode?: string | string[];
  };
};
