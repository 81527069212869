// eslint-disable-next-line no-restricted-imports
import * as Sentry from '@sentry/nextjs'; // only use as type so it's not included in the bundle
import { GetServerSidePropsContext, NextPageContext } from 'next';
import { ErrorInfo } from 'react';
import isBrowser from '../../helpers/isBrowser';
import * as SentryBrowser from './private/sentryBrowser';

export * from './private/sentryOptions';
export class SentryError extends Error {
  cause: unknown;
  constructor(message: string, cause: unknown) {
    super(message);
    this.cause = cause;
  }
}

type CaptureExceptionParams = Parameters<typeof Sentry.captureException>;

export const captureException = (...params: CaptureExceptionParams) => {
  // Dead code elimination is very sensitive, does not work in async and moving the test in a variable outside the scope
  if (process.env.RUNTIME_ENV === 'server') {
    return import('./private/sentryServer').then(({ captureException }) =>
      captureException(...params),
    );
  }
  return SentryBrowser.captureException(...params);
};
export const captureMessage = (...params: Parameters<typeof Sentry.captureMessage>) => {
  if (process.env.RUNTIME_ENV === 'server') {
    return import('./private/sentryServer').then(({ captureMessage }) => captureMessage(...params));
  }
  return SentryBrowser.captureMessage(...params);
};
export const addBreadcrumb = (...params: Parameters<typeof Sentry.addBreadcrumb>) => {
  if (process.env.RUNTIME_ENV === 'server') {
    return import('./private/sentryServer').then(({ addBreadcrumb }) => addBreadcrumb(...params));
  }
  return SentryBrowser.addBreadcrumb(...params);
};

export const lastEventId = () => {
  if (process.env.RUNTIME_ENV === 'server') {
    return import('./private/sentryServer').then(({ lastEventId }) => lastEventId());
  }
  return SentryBrowser.lastEventId();
};

export const setUser = (user: Sentry.User | null) => {
  if (process.env.RUNTIME_ENV === 'server') {
    return import('./private/sentryServer').then(({ setUser }) => setUser(user));
  }
  return SentryBrowser.setUser(user);
};

export const getScopeContext = ({
  ctx,
  errorInfo,
}: {
  ctx?: NextPageContext | GetServerSidePropsContext;
  errorInfo?: ErrorInfo;
}) => {
  const scopeContext: CaptureExceptionParams[1] = {};
  scopeContext.extra = {};
  if (ctx) {
    const { req, res, query } = ctx;
    scopeContext.extra.query = query;

    if (res?.statusCode) {
      scopeContext.extra.statusCode = res.statusCode;
      if (res.statusCode == 404) {
        scopeContext.level = 'warning';
      }
    }

    if (isBrowser() && 'pathname' in ctx) {
      scopeContext.extra.pathname = ctx.pathname;
    } else {
      scopeContext.extra.url = req?.url;
      scopeContext.extra.method = req?.method;
      scopeContext.extra.headers = req?.headers;
    }
  }

  if (errorInfo) {
    scopeContext.extra.componentStack = errorInfo.componentStack;
  }

  return scopeContext;
};
