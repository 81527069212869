import { useRouter } from 'next/router';
import {
  EXCLUDE_FROM_GOOGLE_AND_FACEBOOK_TRACKING_PATHS,
  EXCLUDE_FROM_GOOGLE_AND_FACEBOOK_TRACKING_QUERY_SLUGS,
} from '../constants/excludeFromTracking';
import useOnMountEffect from '../hooks/useOnMountEffect';

const FacebookTracking = () => {
  const router = useRouter();

  const isDisabled =
    EXCLUDE_FROM_GOOGLE_AND_FACEBOOK_TRACKING_PATHS.has(router.asPath) ||
    typeof router.query.ppcPartner === 'string' ||
    (typeof router.query.slug === 'string' &&
      EXCLUDE_FROM_GOOGLE_AND_FACEBOOK_TRACKING_QUERY_SLUGS.has(router.query.slug));

  if (isDisabled) {
    return null;
  }

  return (
    <>
      <FacebookScript />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=1280253975490550&ev=PageView&noscript=1"
  />`,
        }}
      />
    </>
  );
};

const FacebookScript = () => {
  const router = useRouter();
  useOnMountEffect(() => {
    const timeOnPage = 10;
    setTimeout(() => {
      // @ts-expect-error
      if (window.fbq) {
        // @ts-expect-error
        window.fbq('trackCustom', 'Time Tracking', {
          'Time on page': timeOnPage + 'seconds',
          route: router.route,
        });
      }
    }, timeOnPage * 1000);
  });
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '1280253975490550');
      fbq('track', 'PageView');`,
        }}
      />
    </>
  );
};

export default FacebookTracking;
