import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { NAVBAR_HEIGHT_L, breakpoints, colors, spacing } from '../../../../utils/styleguide';
import Container from '../../layout/Container';

const MenuWrapper = styled.div<{ hideBorder: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  border-bottom: ${({ hideBorder }) => (hideBorder ? 'none' : `1px solid ${colors.grey02}`)};
  height: ${NAVBAR_HEIGHT_L}px;

  ${breakpoints.desktop} {
    padding: 0 ${spacing[5]}px;
    justify-content: flex-start;
  }
`;

function NavBarContainer({
  children,
  hideBorder = false,
}: {
  children: ReactNode;
  hideBorder?: boolean;
}) {
  return (
    <Container disableVerticalPadding>
      <MenuWrapper hideBorder={hideBorder}>{children}</MenuWrapper>
    </Container>
  );
}

export default NavBarContainer;
