const mwLogo = `%c
██████████████████████████████████████████████████████████
██████████████████████████████████████████████████████████
█████████████████████████  ███████████████████████████████
█████████████████████████   ██████████████████████████████
█████████████████████████     ████████████████████████████
█████████████████████████       ██████████████████████████
████████████                                  ████████████
██████████████                      ██████  ██████████████
████████████████                      ██   ███████████████
████████████████                         █████████████████
██████████████                         ███████████████████
████████████                         █████████████████████
███████████          █████████████████████████████████████
█████████████        █████████████████████████████████████
███████████████      █████████████████████████████████████
█████████████████    █████████████████████████████████████
███████████████████  █████████████████████████████████████
████████████████████ █████████████████████████████████████
██████████████████████████████████████████████████████████
██████████████████████         ███████████████████████████
██████████████████████       █████████████████████████████
██████████████████████     ███████████████████████████████
██████████████████████    ████████████████████████████████
██████████████████████  ██████████████████████████████████
██████████████████████████████████████████████████████████
██████████████████████████████████████████████████████████
%c
https://careers.milkywire.com/

    ^ Apply here ^
`;

const careers = () =>
  // eslint-disable-next-line
  console.log(
    mwLogo,
    'font-family: monospace',
    'font-family: helvetica; font-weight: bold; font-size: 1.3em;',
  );

export default careers;
