import { setupAmplitude } from '../analytics';
import useOnMountEffect from '../hooks/useOnMountEffect';

type Props = {
  hasCookieConsent: boolean;
};

const AmplitudeTracking = ({ hasCookieConsent }: Props) => {
  useOnMountEffect(() => {
    setupAmplitude(hasCookieConsent);
  });
  return null;
};

export default AmplitudeTracking;
