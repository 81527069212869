import { ChevronLeft } from '@carbon/icons-react';
import styled from '@emotion/styled';
import { useRef } from 'react';
import { useOutsideClick } from '../../../../../utils/hooks/useOutsideClick';
import { colors, spacing } from '../../../../../utils/styleguide';
import Typography from '../../../text/Typography';
import { NavItemType, NavLinkItemType } from '../../navTypes';
import MobileNavBarWrapper from '../MobileNavBarWrapper';
import SubmenuLink from './SubmenuLink';

const SubmenuHeader = styled.button`
  display: flex;
  align-items: center;
  gap: ${spacing[2]}px;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  padding: ${spacing[1]}px 0;
  margin: ${spacing[0]}px 0;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[1]}px;

  width: 100%;
  margin-top: ${spacing[4]}px;

  border-left: 1px solid ${colors.grey02};
`;

type MobileSubmenuProps = {
  submenu: NavLinkItemType | null | undefined;
  isOpened: boolean;
  goBack: () => void;
  clickOutside: (event: Event) => void;
  onLinkedClicked: () => void;
};

const StyledTypography = styled(Typography)`
  padding: 0 ${spacing[4]}px;
  margin-bottom: ${spacing[2]}px;
  display: flex;
`;

const MobileSubmenu = ({
  submenu,
  isOpened,
  goBack,
  clickOutside,
  onLinkedClicked,
}: MobileSubmenuProps) => {
  const mobileSubmenuRef = useRef(null);
  useOutsideClick({ innerRef: mobileSubmenuRef, onOutsideClick: clickOutside });
  const linkGroups: Omit<NavLinkItemType, 'id'>[] =
    submenu?.links?.filter((link): link is NavLinkItemType => 'links' in link) ?? [];
  const navItems = submenu?.links?.filter(
    (link): link is NavItemType => 'href' in link && !!link.href,
  );

  if (linkGroups.length > 0 && !!navItems?.length)
    linkGroups.splice(0, 0, { title: '', links: navItems });

  return (
    <MobileNavBarWrapper isOpened={!!submenu && isOpened} ref={mobileSubmenuRef}>
      <SubmenuHeader onClick={goBack} aria-label={'back'}>
        <ChevronLeft color={colors.blackPrimary} />
        <Typography variant="buttonSmall" tag="p">
          {submenu?.title}
        </Typography>
      </SubmenuHeader>

      {linkGroups?.length > 0 ? (
        linkGroups.map(({ title, links: linkGroupLinks }: Omit<NavLinkItemType, 'id'>, index) => {
          return (
            <LinksContainer key={title + index}>
              {title && (
                <StyledTypography variant="overlineBold" color={colors.blackSecondary}>
                  {title}
                </StyledTypography>
              )}
              {linkGroupLinks
                ?.filter((link): link is NavItemType => 'href' in link)
                .map(({ title, href, description }: NavItemType, index) => {
                  return (
                    <SubmenuLink
                      key={index}
                      href={href}
                      title={title}
                      description={description}
                      onClick={onLinkedClicked}
                    />
                  );
                })}
            </LinksContainer>
          );
        })
      ) : (
        <LinksContainer>
          {navItems?.map(({ title, href, richText, description }: NavItemType, index) => (
            <SubmenuLink
              key={index}
              href={href}
              title={title ?? richText}
              description={description}
              onClick={onLinkedClicked}
            />
          ))}
        </LinksContainer>
      )}
    </MobileNavBarWrapper>
  );
};

export default MobileSubmenu;
