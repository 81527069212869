// Generic reusable events

import { ErrorInfo } from 'react';
import { CampaignIdentifiers } from '../../../types/ImpacterTypes';

export const GENERIC_EVENT_NAMES = {
  notFound: 'not found',
  show404: 'show 404',
  unsupportedBrowser: 'unsupported browser',
  webSessionStart: 'web - session start',
  buttonClick: 'click button',
  navigation: 'navigation',
  clickAppDownload: 'click app download',
  knownCrawlerDetected: 'crawler - known crawler detected',
  clickShareLink: 'share - click share link',
  shareLinkSuccess: 'share - share link success',
  shareLinkCancel: 'share - share link cancel',
  viewBlogPost: 'blog - read blog post',
  shareBlogPost: 'blog - share blog post',
  clientError: 'client error',
  videoPlayError: 'video play error',
  videoPauseError: 'video pause error',
  timeInView: 'time in view',
  clickCarouselButton: 'click carousel button',
} as const;

export type GENERIC_EVENT_PROPERTIES = {
  [GENERIC_EVENT_NAMES.webSessionStart]: undefined;
  [GENERIC_EVENT_NAMES.navigation]: {
    'from path': string;
    'from query': string;
    to: string;
  };
  [GENERIC_EVENT_NAMES.buttonClick]: {
    name?: string;
    'from path'?: string;
    'from query'?: string;
    to?: string;
  };
  [GENERIC_EVENT_NAMES.clickAppDownload]: {
    campaign: string;
    'store name': 'app store' | 'play store';
    creative: string;
  };
  [GENERIC_EVENT_NAMES.unsupportedBrowser]: {
    userAgent: string;
  };
  [GENERIC_EVENT_NAMES.notFound]: {
    type?: string;
    data?: string;
  };
  [GENERIC_EVENT_NAMES.knownCrawlerDetected]: {
    userAgent: string;
    matchedPattern: string;
  };
  [GENERIC_EVENT_NAMES.viewBlogPost]: {
    title: string;
    slug: string;
  };
  [GENERIC_EVENT_NAMES.clickShareLink]: {
    initiator: 'thank you page';
    link?: string;
    campaign: CampaignIdentifiers | string;
  };
  [GENERIC_EVENT_NAMES.shareLinkSuccess]: {
    initiator: 'thank you page';
    link?: string;
    campaign: CampaignIdentifiers | string;
  };
  [GENERIC_EVENT_NAMES.shareBlogPost]: {
    title: string;
    slug: string;
    platform: string;
  };
  [GENERIC_EVENT_NAMES.shareLinkCancel]: {
    initiator: 'thank you page';
    link: string;
    campaign: CampaignIdentifiers | string;
  };
  [GENERIC_EVENT_NAMES.clientError]: {
    error: Error;
    errorInfo: ErrorInfo;
  };
  [GENERIC_EVENT_NAMES.videoPlayError]: {
    error: unknown;
    src?: string;
  };
  [GENERIC_EVENT_NAMES.videoPauseError]: {
    error: unknown;
    src?: string;
  };
  [GENERIC_EVENT_NAMES.show404]: {
    url?: string;
  };
  [GENERIC_EVENT_NAMES.timeInView]: {
    name?: string;
    seconds: number;
  };
  [GENERIC_EVENT_NAMES.clickCarouselButton]: {
    name?: string;
    direction?: 'next' | 'prev';
    currentIndex: number;
    nextIndex: number;
  };
};
