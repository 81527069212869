// Events for priceless planet

export const PPC_EVENT_NAMES = {
  ppcMapRegionSelect: 'PPC - map region select',
  ppcMapRegionSelectSeeMore: 'PPC - map region select - see more',
  ppcMapProjectSelect: 'PPC - map project select',
} as const;

export type OnClickType = 'CARD' | 'MAP' | 'NAVIGATION';

export type PPC_EVENT_PROPERTIES = {
  [PPC_EVENT_NAMES.ppcMapRegionSelect]: {
    regionId: string;
    type: OnClickType;
  };
  [PPC_EVENT_NAMES.ppcMapProjectSelect]: {
    projectId: string;
    type: OnClickType;
  };
  [PPC_EVENT_NAMES.ppcMapRegionSelectSeeMore]: {
    regionId: string;
  };
};
